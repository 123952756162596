var _a;

import { __spreadArray } from "tslib";
import { createSlice } from "@reduxjs/toolkit";
import { RoomSpaceFilterTypes } from "../space/types";
var initialState = {
  notification: undefined,
  errors: [],
  loading: [],
  success: [],
  web: {
    popUp: undefined
  },
  roomToggles: {},
  issueFilters: {},
  roomFilters: {
    room_code: []
  },
  roomSorting: {
    key: "room",
    asc: true
  },
  customSpaceSorting: {
    key: "name",
    asc: true
  },
  sidebarView: true,
  requiredDataState: undefined
};
var uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    addError: function addError(state, _a) {
      var payload = _a.payload;
      var errorIndex = state.errors.indexOf(payload);

      if (errorIndex === -1) {
        state.errors = __spreadArray(__spreadArray([], state.errors, true), [payload], false);
      } // Stop loading when error


      var loadingIndex = state.loading.indexOf(payload);

      if (loadingIndex > -1) {
        state.loading.splice(loadingIndex, 1);
      }
    },
    removeError: function removeError(state, _a) {
      var payload = _a.payload;
      var errorIndex = state.errors.indexOf(payload);

      if (errorIndex > -1) {
        state.errors.splice(errorIndex, 1);
      }
    },
    addSuccess: function addSuccess(state, _a) {
      var payload = _a.payload;
      var successIndex = state.success.indexOf(payload);

      if (successIndex === -1) {
        state.success = __spreadArray(__spreadArray([], state.success, true), [payload], false);
      } // stop loading on success


      var loadingIndex = state.loading.indexOf(payload);

      if (loadingIndex > -1) {
        state.loading.splice(loadingIndex, 1);
      }
    },
    removeSuccess: function removeSuccess(state, _a) {
      var payload = _a.payload;
      var successIndex = state.success.indexOf(payload);

      if (successIndex > -1) {
        state.success.splice(successIndex, 1);
      }
    },
    startLoading: function startLoading(state, _a) {
      var payload = _a.payload; // Reset error when loading a transaction

      var errorIndex = state.errors.indexOf(payload);

      if (errorIndex > -1) {
        state.errors.splice(errorIndex, 1);
      }

      var successIndex = state.errors.indexOf(payload);

      if (successIndex > -1) {
        state.success.splice(successIndex, 1);
      }

      var loadingIndex = state.loading.indexOf(payload);

      if (loadingIndex === -1) {
        state.loading = __spreadArray(__spreadArray([], state.loading, true), [payload], false);
      }
    },
    switchNavigationView: function switchNavigationView(state, _a) {
      var payload = _a.payload;
      state.sidebarView = payload;
    },
    setNotification: function setNotification(state, _a) {
      var payload = _a.payload;
      state.notification = payload;
    },
    showPopUp: function showPopUp(state, _a) {
      var payload = _a.payload;
      state.web.popUp = payload;
    },
    hidePopUp: function hidePopUp(state) {
      state.web.popUp = undefined;
    },
    setRoomToggle: function setRoomToggle(state, _a) {
      var payload = _a.payload;

      if (state.roomToggles[payload]) {
        delete state.roomToggles[payload];
      } else {
        state.roomToggles[payload] = 1;
      }
    },
    setRoomToggles: function setRoomToggles(state, _a) {
      var payload = _a.payload;
      state.roomToggles = payload;
    },
    resetRoomToggles: function resetRoomToggles(state) {
      state.roomToggles = {};
    },
    setIssueFilter: function setIssueFilter(state, _a) {
      var _b = _a.payload,
          filterType = _b.filterType,
          value = _b.value;

      if (value === undefined) {
        delete state.issueFilters[filterType];
      } else {
        //@ts-ignore
        state.issueFilters[filterType] = value;
      }
    },
    setRoomFilter: function setRoomFilter(state, _a) {
      var _b, _c, _d, _e;

      var _f = _a.payload,
          filterType = _f.filterType,
          value = _f.value;

      if (filterType === RoomSpaceFilterTypes.RESET) {
        state.roomFilters = initialState.roomFilters;
      } else if (!value) {
        delete state.roomFilters[filterType];
      } else if (filterType === RoomSpaceFilterTypes.ROOM_CODE) {
        var index = (_c = (_b = state.roomFilters[RoomSpaceFilterTypes.ROOM_CODE]) === null || _b === void 0 ? void 0 : _b.indexOf(value.toString())) !== null && _c !== void 0 ? _c : -1;

        if (index > -1) {
          (_d = state.roomFilters[RoomSpaceFilterTypes.ROOM_CODE]) === null || _d === void 0 ? void 0 : _d.splice(index, 1);
          return;
        }

        (_e = state.roomFilters[RoomSpaceFilterTypes.ROOM_CODE]) === null || _e === void 0 ? void 0 : _e.push(value.toString());
      } else {
        //@ts-ignore
        state.roomFilters[filterType] = value;
      }
    },
    setRoomSorting: function setRoomSorting(state, _a) {
      var key = _a.payload.key;
      var currentAsc = true;

      if (state.roomSorting.key === key) {
        currentAsc = !state.roomSorting.asc;
      }

      state.roomSorting = {
        key: key,
        asc: currentAsc
      };
    },
    setCustomSpaceSorting: function setCustomSpaceSorting(state, _a) {
      var key = _a.payload.key;
      var currentAsc = true;

      if (state.customSpaceSorting.key === key) {
        currentAsc = !state.customSpaceSorting.asc;
      }

      state.customSpaceSorting = {
        key: key,
        asc: currentAsc
      };
    },
    setRequiredDataState: function setRequiredDataState(state, _a) {
      var payload = _a.payload;
      state.requiredDataState = payload;
    }
  }
});
export var setNotification = (_a = uiSlice.actions, _a.setNotification),
    switchNavigationView = _a.switchNavigationView,
    showPopUp = _a.showPopUp,
    hidePopUp = _a.hidePopUp,
    addError = _a.addError,
    startLoading = _a.startLoading,
    addSuccess = _a.addSuccess,
    removeError = _a.removeError,
    removeSuccess = _a.removeSuccess,
    setRoomToggle = _a.setRoomToggle,
    setRoomToggles = _a.setRoomToggles,
    resetRoomToggles = _a.resetRoomToggles,
    setIssueFilter = _a.setIssueFilter,
    setRoomFilter = _a.setRoomFilter,
    setRoomSorting = _a.setRoomSorting,
    setCustomSpaceSorting = _a.setCustomSpaceSorting,
    setRequiredDataState = _a.setRequiredDataState;
export default uiSlice;