export var SpaceTransactionTypes;

(function (SpaceTransactionTypes) {
  // Cleaning actions
  SpaceTransactionTypes["START_CLEANING"] = "space:clean:start";
  SpaceTransactionTypes["STOP_CLEANING"] = "space:clean:stop";
  SpaceTransactionTypes["FINISH_CLEANING"] = "space:clean:finish";
  SpaceTransactionTypes["SKIP_CLEANING"] = "space:clean:skip"; // Assign actions

  SpaceTransactionTypes["ASSIGN_SPACE"] = "space:assign:add";
  SpaceTransactionTypes["UNASSIGN_SPACE"] = "space:assign:remove"; // Inspect actions

  SpaceTransactionTypes["FINISH_INSPECTION"] = "space:inspect:finish";
})(SpaceTransactionTypes || (SpaceTransactionTypes = {}));

export var IssueTransactionTypes;

(function (IssueTransactionTypes) {
  // Cleaning actions
  IssueTransactionTypes["START_WORK"] = "issue:work:start";
  IssueTransactionTypes["STOP_WORK"] = "issue:work:stop";
  IssueTransactionTypes["FINISH_WORK"] = "issue:work:finish";
  IssueTransactionTypes["CREATE"] = "issue:report:create";
  IssueTransactionTypes["ASSIGN"] = "issue:assign:add";
  IssueTransactionTypes["UNASSIGN"] = "issue:assign:remove";
})(IssueTransactionTypes || (IssueTransactionTypes = {}));

export var AttachmentsTransactionTypes;

(function (AttachmentsTransactionTypes) {
  AttachmentsTransactionTypes["ADD_ATTACHMENT"] = "issue:report:attach";
})(AttachmentsTransactionTypes || (AttachmentsTransactionTypes = {}));