import{createTheme}from"baseui";var primitives={primaryFontFamily:"SourceSansPro-Regular",// Gray
mono100:"#F8F8F8",mono200:"#EFEFEF",mono300:"#E0E0E0",mono400:"#D6D6D6",mono500:"#C2C2C2",mono600:"#999999",mono700:"#666666",mono800:"#3D3D3D",mono900:"#292929",mono1000:"#1F1F1F",// Blue
primaryA:"#2BB9F5",primary50:"#092531",primary100:"#1AA0E2",primary200:"#B5E6FA",primary300:"#E2F5FE",// Only used for sidebars etc
primary400:"#1B556B",primary500:"#164456",primary600:"#103340",// Orange
accent:"#F7933B",accent50:"#984A04",accent100:"#D46706",accent200:"#FAAb65",accent300:"#FFEEDE",// Red
negative:"#F24851",negative50:"#BA071F",negative100:"#E82637",negative200:"#F0979A",negative300:"#FFEAEE",// Yellow
warning:"#FEDC56",warning50:"#B18E00",warning100:"#EDBE04",warning200:"#FEF4AB",warning300:"#FFFDEB",// Green
positive:"#86C840",positive50:"#216F0E",positive100:"#61A52D",positive200:"#C4E4A1",positive300:"#F1F9E8"};var overrides={colors:{// Button primary
buttonPrimaryFill:primitives.accent,buttonPrimaryText:primitives.mono100,buttonPrimaryHover:primitives.accent200,buttonPrimaryActive:primitives.accent200,buttonPrimaryGhostHover:primitives.accent300,buttonPrimarySpinnerForeground:primitives.primaryB,buttonPrimarySpinnerBackground:primitives.accent200,// Button secondary
buttonSecondaryFill:"transparent",buttonSecondaryText:primitives.mono100,buttonSecondaryHover:primitives.accent,buttonSecondaryActive:primitives.accent,buttonSecondarySpinnerForeground:primitives.accent,buttonSecondarySpinnerBackground:primitives.primary200,// Button tertiary
buttonTertiaryFill:primitives.primaryA,buttonTertiaryText:primitives.mono100,buttonTertiaryHover:primitives.primary200,buttonTertiaryActive:primitives.primary200,buttonTertiarySpinnerForeground:primitives.primaryB,buttonTertiarySpinnerBackground:primitives.primary400,// Tables
tableStripedBackground:primitives.primary50,// Links
linkText:primitives.primaryA,linkVisited:primitives.primary100,linkHover:primitives.primary100,linkActive:primitives.primary100,// Content
contentPrimary:primitives.mono1000,contentSecondary:primitives.mono1000,contentTertiary:primitives.mono1000,// Background
backgroundPrimary:primitives.mono100,backgroundSecondary:primitives.mono200,backgroundTertiary:primitives.mono300,// Notification
notificationPrimaryBackground:primitives.mono100,notificationPrimaryText:primitives.primaryB,notificationInfoBackground:primitives.primaryA,notificationInfoText:primitives.primary300,notificationPositiveBackground:primitives.positive,notificationPositiveText:primitives.positive300,notificationWarningBackground:primitives.warning,notificationWarningText:primitives.warning300,notificationNegativeBackground:primitives.negative,notificationNegativeText:primitives.negative300,actionRedDark:"#DE0A12",alertBlue:"#e6f8fe",alertRed:"#F8DADC",blue:"#00adf2",green:"#6cc123",red:"#ff1c25",orange:"#ff8116",orangeDark:"#FF8100",orangeLight:"#FFE6CC",dark:"#010F14",gray:"#e6e6e6",lightGray:"#ACACAC",yellow:"#fefad6",yellowDark:"#ffe5ac",primary:"#d8b379",lightRed:"#fbe2e3",lightGreen:"#f0f9e3",brandBlue:"#154D60",turquoise:"#DAE8ED",purple:"#5D4EA2",brandGoldDark:"#BA7C1C",black:"#000",defaultRoomState:"#E2E2E2"},sizing:{scale100:"2px",scale200:"4px",scale400:"8px",scale500:"10px",scale600:"12px",scale800:"16px",scale1000:"20px",scale1200:"24px",scale1600:"32px",scale1800:"48px"},borders:{radius100:"2px",radius200:"4px",radius300:"8px",radius400:"12px",radius500:"16px",radius600:"20px"}};export default createTheme(primitives,overrides);