var _a;

import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import axios from "axios";
import helpers, { Auth } from "./helpers";
import humps from "humps";
import Platform from "react-native-web/dist/cjs/exports/Platform";
import packageJson from "@hktd/common/package.json";
import store from "../store";
import { signOut } from "../store/user/userThunk";
import l from "../locale";
import additionalHeaders from "./additionalHeaders";
import Bugsnag from "./bugsnag";
var isWeb = Platform.OS === "web";
export var API_ROOT = "https://api-staging.valkdigitalservices.nl";

if (isWeb && true && ["hktd.valkdigital.nl", "hktd-dashboard.valkdigitalservices.nl"].includes((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) {
  API_ROOT = "https://api.valkdigitalservices.nl";
} else if (!isWeb && !__DEV__) {
  API_ROOT = "https://api.valkdigitalservices.nl";
}

var headers = __assign({
  "X-Application": "hktd-".concat(Platform.OS, "-").concat(packageJson.version)
}, additionalHeaders);

var uuidV4Regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
export var client = axios.create({
  baseURL: "".concat(API_ROOT, "/v1/hktd"),
  // Awaiting pagination on backend side
  timeout: 10000,
  headers: headers,
  transformResponse: __spreadArray(__spreadArray([], axios.defaults.transformResponse, true), [function (data) {
    return humps.camelizeKeys(data, function (key, convert) {
      return uuidV4Regex.test(key) ? key : convert(key);
    });
  }], false),
  transformRequest: __spreadArray([function (data) {
    return humps.decamelizeKeys(data);
  }], axios.defaults.transformRequest, true)
});
export var getToken = function getToken() {
  return __awaiter(void 0, void 0, void 0, function () {
    var auth;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , Auth.get()];

        case 1:
          auth = _a.sent();
          if (!auth) return [2
          /*return*/
          , false];
          return [2
          /*return*/
          , auth.token];
      }
    });
  });
};
export var tokenHeader = function tokenHeader(token) {
  if (!token) return {};
  return {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Accept-Language": l.language
    }
  };
};

var logoutInvalidUser = function logoutInvalidUser(message, error) {
  setAxiosMetaData(error);
  Bugsnag.notify({
    name: "Logout invalid user",
    message: message
  });
  store.dispatch(signOut(true));
  if (isWeb && true) window.location.reload();
};

client.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return __awaiter(void 0, void 0, void 0, function () {
    var config, response, originalRequest, retrying, _a, token, refreshToken;

    var _b, _c, _d;

    return __generator(this, function (_e) {
      switch (_e.label) {
        case 0:
          config = error.config, response = error.response;
          originalRequest = config;
          retrying = "refresh_token" in JSON.parse((_b = config.data) !== null && _b !== void 0 ? _b : "{}");

          if (((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.code) === "hktd.unauthorized") {
            console.log("User is unauthorized, logging out");
            return [2
            /*return*/
            , logoutInvalidUser("Unauthorized", error)];
          }

          if (((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.code) !== "invalid_token") return [2
          /*return*/
          , Promise.reject(error)];
          if (!retrying) return [3
          /*break*/
          , 2];
          return [4
          /*yield*/
          , setAxiosMetaData(error)];

        case 1:
          _e.sent();

          Bugsnag.notify({
            name: "Refresh Token fail",
            message: JSON.stringify(error)
          });
          clearAxiosMetaData();
          logoutInvalidUser("Refresh token fail", error);
          return [2
          /*return*/
          ];

        case 2:
          return [4
          /*yield*/
          , refreshTokenPromise()];

        case 3:
          _a = _e.sent().data, token = _a.token, refreshToken = _a.refreshToken;
          return [4
          /*yield*/
          , Auth.update(token, refreshToken)];

        case 4:
          _e.sent();

          originalRequest.headers["Authorization"] = "Bearer ".concat(token);
          return [2
          /*return*/
          , axios.request(originalRequest)];
      }
    });
  });
});
var refreshTokenStarted = false;
var tokenPromise;

var refreshTokenPromise = function refreshTokenPromise() {
  return __awaiter(void 0, void 0, void 0, function () {
    var auth;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!!refreshTokenStarted) return [3
          /*break*/
          , 2];
          refreshTokenStarted = true;
          return [4
          /*yield*/
          , Auth.get()];

        case 1:
          auth = _a.sent();
          tokenPromise = client.post("/sessions", {
            refresh_token: auth === null || auth === void 0 ? void 0 : auth.refreshToken
          });
          tokenPromise["finally"](function () {
            tokenPromise = null;
            refreshTokenStarted = false;
          });
          _a.label = 2;

        case 2:
          return [2
          /*return*/
          , tokenPromise];
      }
    });
  });
};

var setAxiosMetaData = function setAxiosMetaData(e) {
  return __awaiter(void 0, void 0, void 0, function () {
    var tokens, jwtTokenAvailable, refreshTokenAvailable;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , Auth.get()];

        case 1:
          tokens = _a.sent();
          jwtTokenAvailable = !!(tokens === null || tokens === void 0 ? void 0 : tokens.token);
          refreshTokenAvailable = !!(tokens === null || tokens === void 0 ? void 0 : tokens.refreshToken);
          Bugsnag.addMetadata("Axios", {
            request: JSON.stringify(e.request),
            response: JSON.stringify(e.response),
            tokens: {
              jwtTokenAvailable: jwtTokenAvailable,
              refreshTokenAvailable: refreshTokenAvailable
            }
          });
          return [2
          /*return*/
          ];
      }
    });
  });
};

var clearAxiosMetaData = function clearAxiosMetaData() {
  Bugsnag.clearMetadata("Axios");
};

var handleErrors = function handleErrors(e) {
  return __awaiter(void 0, void 0, void 0, function () {
    var message;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          message = "error";
          return [4
          /*yield*/
          , setAxiosMetaData(e)];

        case 1:
          _a.sent();

          Bugsnag.notify({
            name: "Axios Error",
            message: JSON.stringify(e)
          });
          clearAxiosMetaData();

          if (e.response && e.response.data && e.response.data.error) {
            message = e.response.data.error;
          }

          throw message;
      }
    });
  });
};

export var requests = {
  del: function del(url, data) {
    return __awaiter(void 0, void 0, void 0, function () {
      var token, res, er_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);

            return [4
            /*yield*/
            , getToken()];

          case 1:
            token = _a.sent();
            return [4
            /*yield*/
            , client["delete"](url, __assign({
              data: data
            }, tokenHeader(token)))];

          case 2:
            res = _a.sent();
            return [2
            /*return*/
            , res.data];

          case 3:
            er_1 = _a.sent();
            return [2
            /*return*/
            , handleErrors(er_1)];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  },
  get: function get(url, rawParams) {
    return __awaiter(void 0, void 0, void 0, function () {
      var params, token, res, er_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);

            params = rawParams ? humps.decamelizeKeys(rawParams) : {};
            return [4
            /*yield*/
            , getToken()];

          case 1:
            token = _a.sent();
            return [4
            /*yield*/
            , client.get(url, __assign(__assign({}, tokenHeader(token)), {
              params: params
            }))];

          case 2:
            res = _a.sent();
            return [2
            /*return*/
            , res.data];

          case 3:
            er_2 = _a.sent();
            return [2
            /*return*/
            , handleErrors(er_2)];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  },
  publicGet: function publicGet(url) {
    return __awaiter(void 0, void 0, void 0, function () {
      var res, er_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , client.get(url, {
              headers: {
                // "Accept-Language": l.language,
                "Cache-Control": "no-cache"
              }
            })];

          case 1:
            res = _a.sent();
            return [2
            /*return*/
            , res.data];

          case 2:
            er_3 = _a.sent();
            return [2
            /*return*/
            , handleErrors(er_3)];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  },
  put: function put(url, body) {
    return __awaiter(void 0, void 0, void 0, function () {
      var token, res, er_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);

            return [4
            /*yield*/
            , getToken()];

          case 1:
            token = _a.sent();
            return [4
            /*yield*/
            , client.put(url, body, tokenHeader(token))];

          case 2:
            res = _a.sent();
            return [2
            /*return*/
            , res.data];

          case 3:
            er_4 = _a.sent();
            return [2
            /*return*/
            , handleErrors(er_4)];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  },
  post: function post(url, body) {
    return __awaiter(void 0, void 0, void 0, function () {
      var token, res, er_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);

            return [4
            /*yield*/
            , getToken()];

          case 1:
            token = _a.sent();
            return [4
            /*yield*/
            , client.post(url, body, tokenHeader(token))];

          case 2:
            res = _a.sent();
            return [2
            /*return*/
            , res.data];

          case 3:
            er_5 = _a.sent();
            return [2
            /*return*/
            , handleErrors(er_5)];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  },
  rawPost: function rawPost(url, body) {
    return __awaiter(void 0, void 0, void 0, function () {
      var token;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , getToken()];

          case 1:
            token = _a.sent();
            return [2
            /*return*/
            , client.post(url, body, tokenHeader(token))];
        }
      });
    });
  },
  publicPost: function publicPost(url, body) {
    return __awaiter(void 0, void 0, void 0, function () {
      var res, er_6;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , client.post(url, body, {
              headers: {
                "Accept-Language": l.language
              },
              baseURL: API_ROOT
            })];

          case 1:
            res = _a.sent();
            return [2
            /*return*/
            , res.data];

          case 2:
            er_6 = _a.sent();
            return [2
            /*return*/
            , handleErrors(er_6)];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  }
};

var ApiService = function () {
  function ApiService() {
    var _this = this;

    this.Transactions = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/transactions")];
          });
        });
      },
      getByPage: function getByPage(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/transactions", params)];
          });
        });
      },
      create: function create(batch) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/transactions", {
              batch: batch
            })];
          });
        });
      }
    };
    this.Users = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/users")];
          });
        });
      },
      getById: function getById(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/users/".concat(id))];
          });
        });
      },
      create: function create(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/users", params)];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/users/".concat(id), params)];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/users/".concat(id))];
          });
        });
      },
      switchRole: function switchRole(role) {
        return __awaiter(_this, void 0, void 0, function () {
          var response;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4
                /*yield*/
                , this.client.put("/sessions/current", {
                  role: role
                })];

              case 1:
                response = _a.sent();
                return [4
                /*yield*/
                , helpers.Auth.update(response.token, response.refreshToken)];

              case 2:
                _a.sent();

                return [2
                /*return*/
                , true];
            }
          });
        });
      },
      resetPassword: function resetPassword(email) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/users/password_reset", {
              email: email
            })];
          });
        });
      }
    };
    this.Areas = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/areas")];
          });
        });
      },
      create: function create(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/areas", params)];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/areas/".concat(id), params)];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/areas/".concat(id))];
          });
        });
      }
    };
    this.Attachment = {
      add: function add() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/attachments", {})];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/attachments/".concat(id))];
          });
        });
      }
    };
    this.Tasks = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/tasks")];
          });
        });
      },
      getById: function getById(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/tasks/".concat(id))];
          });
        });
      },
      create: function create(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/tasks", params)];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/tasks/".concat(id), params)];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/tasks/".concat(id))];
          });
        });
      }
    };
    this.Auth = {
      signInWithEmail: function signInWithEmail(username, password) {
        return __awaiter(_this, void 0, void 0, function () {
          var response, error_1;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                _a.trys.push([0, 3,, 4]);

                return [4
                /*yield*/
                , this.client.post("/sessions", {
                  email: username,
                  password: password,
                  grant: "credentials"
                })];

              case 1:
                response = _a.sent();
                return [4
                /*yield*/
                , helpers.Auth.update(response.token, response.refreshToken)];

              case 2:
                _a.sent();

                return [2
                /*return*/
                , true];

              case 3:
                error_1 = _a.sent();
                return [2
                /*return*/
                , false];

              case 4:
                return [2
                /*return*/
                ];
            }
          });
        });
      },
      signInWithQr: function signInWithQr(userId) {
        return __awaiter(_this, void 0, void 0, function () {
          var response, error_2;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                _a.trys.push([0, 3,, 4]);

                return [4
                /*yield*/
                , this.client.post("/sessions", {
                  id: userId,
                  grant: "credentials"
                })];

              case 1:
                response = _a.sent();
                return [4
                /*yield*/
                , helpers.Auth.update(response.token, response.refreshToken)];

              case 2:
                _a.sent();

                return [2
                /*return*/
                , true];

              case 3:
                error_2 = _a.sent();
                return [2
                /*return*/
                , false];

              case 4:
                return [2
                /*return*/
                ];
            }
          });
        });
      }
    };
    this.Issues = {
      "delete": function _delete(issueId) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("issues/".concat(issueId))];
          });
        });
      },
      update: function update(issueId, updatedParams) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("issues/".concat(issueId), updatedParams)];
          });
        });
      },
      getPage: function getPage(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/issues", params)];
          });
        });
      },
      getById: function getById(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("issues/".concat(id))];
          });
        });
      }
    };
    this.Property = {
      getById: function getById(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/properties/".concat(id))];
          });
        });
      },
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/properties")];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/properties/".concat(id), params)];
          });
        });
      },
      "switch": function _switch(propertyId) {
        return __awaiter(_this, void 0, void 0, function () {
          var response;
          return __generator(this, function (_a) {
            switch (_a.label) {
              case 0:
                return [4
                /*yield*/
                , this.client.put("/sessions/current", {
                  propertyId: propertyId
                })];

              case 1:
                response = _a.sent();
                return [4
                /*yield*/
                , helpers.Auth.update(response.token, response.refreshToken)];

              case 2:
                _a.sent();

                return [2
                /*return*/
                , true];
            }
          });
        });
      }
    };
    this.Stats = {
      getStats: function getStats() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            ];
          });
        });
      }
    };
    this.Spaces = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/spaces")];
          });
        });
      },
      getById: function getById(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/spaces/".concat(id))];
          });
        });
      },
      createCustomSpace: function createCustomSpace(newSpace) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/spaces", newSpace)];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/spaces/".concat(id), params)];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/spaces/".concat(id))];
          });
        });
      }
    };
    this.DayReports = {
      getPage: function getPage(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/day_reports", __assign({}, params))];
          });
        });
      },
      getById: function getById(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/day_reports/".concat(id))];
          });
        });
      },
      getByDate: function getByDate(date) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/day_reports/date:".concat(date))];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/day_reports/".concat(id), params)];
          });
        });
      },
      deleteRemark: function deleteRemark(id, auditId) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/day_reports/".concat(id, "/remarks/").concat(auditId))];
          });
        });
      },
      approve: function approve(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/day_reports/".concat(id, "/approve"))];
          });
        });
      },
      deliver: function deliver(date) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/day_reports/".concat(date, "/deliver"))];
          });
        });
      }
    };
    this.MobileKey = {
      requestKey: function requestKey(roomId) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.rawPost("/mobile_keys/credentials", {
              room: roomId
            })];
          });
        });
      }
    };
    this.Device = {
      update: function update(data) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/devices/resource", data)];
          });
        });
      },
      enable: function enable(data) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , requests.publicPost("/devices/resource/register", data)];
          });
        });
      },
      disable: function disable(data) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/devices/resource/register", data)];
          });
        });
      }
    };
    this.RateTypes = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/rate_types")];
          });
        });
      },
      create: function create(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/rate_types", params)];
          });
        });
      },
      update: function update(id, params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.put("/rate_types/".concat(id), params)];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/rate_types/".concat(id))];
          });
        });
      }
    };
    this.ReportExtras = {
      getAll: function getAll() {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/report_extras")];
          });
        });
      },
      create: function create(params) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.post("/report_extras", params)];
          });
        });
      },
      "delete": function _delete(id) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.del("/report_extras/".concat(id))];
          });
        });
      }
    };
    this.Topics = {
      getAllByPushToken: function getAllByPushToken(pushToken) {
        return __awaiter(_this, void 0, void 0, function () {
          return __generator(this, function (_a) {
            return [2
            /*return*/
            , this.client.get("/topics", {
              pushToken: pushToken
            })];
          });
        });
      },
      subscribeToTopic: function subscribeToTopic(params) {
        return _this.client.post("/topics", params);
      },
      unsubscribeFromTopic: function unsubscribeFromTopic(params) {
        return _this.client.del("/topics", params);
      },
      resubscribeTopicsToNewLanguage: function resubscribeTopicsToNewLanguage(params) {
        return _this.client.put("/topics", params);
      }
    };
    this.client = requests;
  }

  return ApiService;
}();

export default new ApiService();