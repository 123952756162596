import { createSelector } from "@reduxjs/toolkit";
import { topicSelectors } from "./notificationSlice";

var selectSelf = function selectSelf(state) {
  return state.notification;
};

export var selectAllTopics = topicSelectors.selectAll;
export var selectNewNotificationsAvailable = createSelector(selectSelf, function (state) {
  return state.newNotificationsAvailable;
});