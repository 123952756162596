var _a;

import { createSelector } from "@reduxjs/toolkit";
import { dayReportAdapter } from "./dayReportSlice";

var selectSelf = function selectSelf(state) {
  return state.dayReport;
};

export var selectDayReportMeta = createSelector(selectSelf, function (_a) {
  var meta = _a.meta;
  return meta;
});
export var selectCurrentDayReport = createSelector(selectSelf, function (_a) {
  var current = _a.current;
  return current;
});
export var selectWeekOverview = createSelector(selectSelf, function (_a) {
  var week = _a.week;
  return week;
});
export var selectDayReportByDate = createSelector(selectSelf, function (_a) {
  var current = _a.current;
  return current;
});
export var selectDayReportLoading = createSelector(selectSelf, function (_a) {
  var loading = _a.loading;
  return loading;
});
export var selectDayReportError = createSelector(selectSelf, function (_a) {
  var error = _a.error;
  return error;
});
export var selectDayReportEntities = (_a = dayReportAdapter.getSelectors(), _a.selectEntities),
    selectDayReportById = _a.selectById,
    selectAllDayReports = _a.selectAll;
export default {
  selectDayReportEntities: selectDayReportEntities,
  selectDayReportById: selectDayReportById,
  selectAllDayReports: selectAllDayReports,
  selectDayReportMeta: selectDayReportMeta,
  selectDayReportLoading: selectDayReportLoading,
  selectDayReportError: selectDayReportError
};