var _a;

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var propertyAdapter = createEntityAdapter({
  sortComparer: function sortComparer(a, b) {
    return a.name.localeCompare(b.name);
  }
});
var propertySlice = createSlice({
  name: "property",
  initialState: propertyAdapter.getInitialState(),
  reducers: {
    setProperties: propertyAdapter.setAll,
    setProperty: propertyAdapter.upsertOne
  }
});
export var setProperties = (_a = propertySlice.actions, _a.setProperties),
    setProperty = _a.setProperty;
export default propertySlice;