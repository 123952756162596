var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var areaAdapter = createEntityAdapter();

var initialState = __assign(__assign({}, areaAdapter.getInitialState()), {
  selectedId: undefined
});

var areaSlice = createSlice({
  name: "areas",
  initialState: initialState,
  reducers: {
    addArea: areaAdapter.addOne,
    setAreas: function setAreas(state, _a) {
      var payload = _a.payload;
      areaAdapter.setAll(state, payload);
    },
    setSelectedArea: function setSelectedArea(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    },
    removeArea: areaAdapter.removeOne,
    upsertArea: areaAdapter.upsertOne
  }
});
export var addArea = (_a = areaSlice.actions, _a.addArea),
    setAreas = _a.setAreas,
    removeArea = _a.removeArea,
    upsertArea = _a.upsertArea,
    setSelectedArea = _a.setSelectedArea;
export default areaSlice;