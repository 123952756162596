var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var rateTypeAdapter = createEntityAdapter();

var initialState = __assign(__assign({}, rateTypeAdapter.getInitialState()), {
  selectedId: undefined
});

var rateTypeSlice = createSlice({
  name: "rateType",
  initialState: initialState,
  reducers: {
    setRateTypes: rateTypeAdapter.setAll,
    setSelectedRateType: function setSelectedRateType(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    }
  }
});
export var setRateTypes = (_a = rateTypeSlice.actions, _a.setRateTypes),
    setSelectedRateType = _a.setSelectedRateType;
export default rateTypeSlice;