import i18next from "i18next";
import en from "./en";
import nl from "./nl";
import de from "./de";
import { initReactI18next } from "react-i18next";
var l = i18next.use(initReactI18next).createInstance({
  fallbackLng: "nl",
  lng: "nl",
  resources: {
    en: {
      translation: en
    },
    nl: {
      translation: nl
    },
    de: {
      translation: de
    }
  }
}, function (err, t) {
  if (err) return console.log("something went wrong loading", err);
  t("key"); // -> same as i18next.t
});
export default l;