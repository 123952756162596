import { __awaiter, __generator } from "tslib";
import api from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import l from "../../locale";
import { batch } from "react-redux";
import { addTask, removeTask, setTask, setTasks } from "./taskSlice";
export var fetchTasks = function fetchTasks() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Tasks.getAll()];

          case 1:
            response = _a.sent();
            dispatch(setTasks(response.data));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchTask = function fetchTask(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Tasks.getById(id)];

          case 1:
            response = _a.sent();
            dispatch(setTask(response));
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createTask = function createTask(task) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_1, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("task");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Tasks.create(task)];

          case 2:
            response_1 = _a.sent();
            batch(function () {
              dispatch(addTask(response_1));
              dispatch(showNotification({
                type: "success",
                message: l.t("addSuccess", {
                  value: value
                })
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_3 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("addFailed", {
                value: value
              })
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateTask = function updateTask(taskId, task) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_2, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("task");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Tasks.update(taskId, task)];

          case 2:
            response_2 = _a.sent();
            batch(function () {
              dispatch(setTask(response_2));
              dispatch(showNotification({
                type: "success",
                message: l.t("updateSuccess", {
                  value: value
                })
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("updateFailed", {
                value: value
              })
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteTask = function deleteTask(taskId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Tasks["delete"](taskId)];

          case 1:
            _a.sent();

            dispatch(removeTask(taskId));
            dispatch(showNotification({
              type: "success",
              message: l.t("deleteSuccess", {
                value: l.t("task")
              })
            }));
            return [3
            /*break*/
            , 3];

          case 2:
            error_5 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("deleteFailed", {
                value: l.t("task")
              })
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};