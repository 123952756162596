import { createSelector } from "@reduxjs/toolkit";
import { issueSelectors } from "./issueSlice";
import { IssueFilterTypes, IssueState } from "./types";

var selectSelf = function selectSelf(state) {
  return state.issue;
};

export var selectIssueById = issueSelectors.selectById,
    selectIssueEntities = issueSelectors.selectEntities,
    selectAllIssueIds = issueSelectors.selectIds,
    selectTotalIssues = issueSelectors.selectTotal,
    selectAllIssues = issueSelectors.selectAll;
export var selectCurrentIssueId = createSelector(selectSelf, function (store) {
  return store.selectedId;
});
export var selectCurrentIssue = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      selectedId = _a.selectedId;
  if (selectedId) return entities[selectedId];
  return undefined;
});
export var selectMaintenanceStats = createSelector(selectSelf, function (_a) {
  var ids = _a.ids,
      entities = _a.entities;
  var stats = {
    on_hold: 0,
    in_progress: 0,
    open: 0,
    done: 0
  };
  ids.forEach(function (id) {
    var issue = entities[id];
    stats[issue.state]++;
  });
  return stats;
});
export var selectPaginationMeta = createSelector(selectSelf, function (_a) {
  var meta = _a.meta;
  return meta !== null && meta !== void 0 ? meta : {
    count: 0,
    last: 1,
    next: null,
    prev: null,
    page: 1,
    pages: 1
  };
});

var filterIssuesWithFilters = function filterIssuesWithFilters(filters, issue) {
  var _a;

  var tabFilter = (_a = filters[IssueFilterTypes.STATUS_TAB]) !== null && _a !== void 0 ? _a : IssueState.OPEN;
  if (tabFilter !== undefined && issue.state !== tabFilter) return false;
  if (issue.state === IssueState.DONE && !doneFilter(issue)) return false;
  return true;
};

var searchFilter = function searchFilter(members, spaces, filters, issue) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j;

  var searchFilter = (_a = filters[IssueFilterTypes.SEARCH_TEXT]) !== null && _a !== void 0 ? _a : "";
  return [(_f = (_e = (_d = members[(_c = (_b = issue.users) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id]) === null || _d === void 0 ? void 0 : _d.profile) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.toLowerCase(), (_h = (_g = spaces[issue.spaceId]) === null || _g === void 0 ? void 0 : _g.label) === null || _h === void 0 ? void 0 : _h.toLowerCase(), (_j = issue.label) === null || _j === void 0 ? void 0 : _j.toLowerCase()].some(function (item) {
    return item === null || item === void 0 ? void 0 : item.includes(searchFilter.toLowerCase());
  });
};

var doneFilter = function doneFilter(issue) {
  return issue.state === IssueState.DONE && issue.workLogs !== null && issue.workLogs.length > 0;
};

export var selectIssuesByFilters = createSelector(function (state) {
  return state.ui.issueFilters;
}, function (state) {
  return state.issue.ids;
}, function (state) {
  return state.issue.entities;
}, function (state) {
  return state.space.entities;
}, function (state) {
  return state.user.entities;
}, function (filters, ids, issues, spaces, members) {
  var _a;

  var filteredIssues = [];
  var result = {
    on_hold: 0,
    in_progress: 0,
    open: 0,
    done: 0
  };
  ids.forEach(function (id) {
    var issue = issues[id];
    if (issue === undefined) return;
    if (!searchFilter(members, spaces, filters, issue)) return;

    if ((issue === null || issue === void 0 ? void 0 : issue.state) === IssueState.DONE && doneFilter(issue)) {
      result.done += 1;
    } else {
      result[issue.state] += 1;
    }

    if (filterIssuesWithFilters(filters, issue)) {
      filteredIssues.push(issue);
    }
  });
  return {
    issues: filteredIssues,
    issueCount: result,
    selectedTab: (_a = filters[IssueFilterTypes.STATUS_TAB]) !== null && _a !== void 0 ? _a : IssueState.OPEN
  };
});