import { __awaiter, __generator } from "tslib";
import services from "../../services/api";
import l from "../../locale";
import { showNotification } from "../ui/uiThunk";
import { batch } from "react-redux";
import { setRateTypes } from "./rateTypeSlice";
export var fetchRateTypes = function fetchRateTypes() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var data, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.RateTypes.getAll()];

          case 1:
            data = _a.sent().data;
            dispatch(setRateTypes(data));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createRateType = function createRateType(rateType) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("rateType");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.RateTypes.create(rateType)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(showNotification({
                message: l.t("addSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(fetchRateTypes());
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_2 = _a.sent();
            dispatch(showNotification({
              message: l.t("addFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateRateType = function updateRateType(id, rateType) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("rateType");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.RateTypes.update(id, rateType)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(showNotification({
                message: l.t("updateSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(fetchRateTypes());
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_3 = _a.sent();
            dispatch(showNotification({
              message: l.t("updateFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteRateType = function deleteRateType(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("rateType");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.RateTypes["delete"](id)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(showNotification({
                message: l.t("deleteSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(fetchRateTypes());
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _a.sent();
            dispatch(showNotification({
              message: l.t("deleteFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};