var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var transactionAdapter = createEntityAdapter();

var initialState = __assign(__assign({}, transactionAdapter.getInitialState()), {
  selectedId: undefined
});

var transactionSlice = createSlice({
  name: "transaction",
  initialState: initialState,
  reducers: {
    setTransactions: transactionAdapter.setAll,
    setTransactionMeta: function setTransactionMeta(state, _a) {
      var payload = _a.payload;
      state.meta = payload;
    }
  }
});
export var setTransactionMeta = (_a = transactionSlice.actions, _a.setTransactionMeta),
    setTransactions = _a.setTransactions;
export default transactionSlice;