import { addError, setNotification, addSuccess } from "./uiSlice";
import { batch } from "react-redux";
var timer;
export var showNotification = function showNotification(notification, action) {
  return function (dispatch, getState) {
    if (getState().ui.notification && notification.noOverride) return;
    batch(function () {
      if (action && notification.type === "success") {
        dispatch(addSuccess(action));
      }

      if (action && notification.type === "error") {
        dispatch(addError(action));
      }

      dispatch(setNotification(notification));
    });
    var time = 3000; // Every 180 extra characters should add 1 second to the timer

    time += Math.floor(notification.message.length / 180) * 1000;
    clearTimeout(timer);
    timer = setTimeout(function () {
      dispatch(setNotification(undefined));
    }, time);
  };
};