var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var taskAdapter = createEntityAdapter();

var initialState = __assign(__assign({}, taskAdapter.getInitialState()), {
  selectedId: undefined
});

var taskSlice = createSlice({
  name: "tasks",
  initialState: initialState,
  reducers: {
    addTask: taskAdapter.addOne,
    setTasks: taskAdapter.setAll,
    removeTask: taskAdapter.removeOne,
    setTask: taskAdapter.upsertOne,
    setSelectedTask: function setSelectedTask(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    }
  }
});
export var addTask = (_a = taskSlice.actions, _a.addTask),
    setTasks = _a.setTasks,
    removeTask = _a.removeTask,
    setTask = _a.setTask,
    setSelectedTask = _a.setSelectedTask;
export default taskSlice;