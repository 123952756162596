import { __assign, __awaiter, __generator } from "tslib";
import services from "../../services/api";
import { batch } from "react-redux";
import helpers from "../../services/helpers";
import { showNotification } from "../ui/uiThunk";
import l from "../../locale";
import { SpaceTransactionTypes } from "../../types/transaction";
import { commitInspectionTransaction, commitSpaceTransaction, removeSpace, setSpace, setSpaces } from "./spaceSlice";
import { addSuccess, startLoading } from "../ui/uiSlice";
import { transactionErrorMessage } from "../../helpers";
export var handleSpaceTransaction = function handleSpaceTransaction(actions) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var name, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            name = actions[0].name;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading(name));
            return [4
            /*yield*/
            , services.Transactions.create(actions)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(commitSpaceTransaction(actions));
              dispatch(addSuccess(name));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_1 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: transactionErrorMessage(name)
              }, name));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var handleInspectionTransaction = function handleInspectionTransaction(_a) {
  var transaction = _a.transaction,
      oldSpace = _a.oldSpace,
      newInspection = _a.newInspection;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            dispatch(startLoading(transaction.name));
            return [4
            /*yield*/
            , services.Transactions.create([transaction])];

          case 1:
            _a.sent();

            batch(function () {
              dispatch(commitInspectionTransaction({
                transaction: transaction,
                newInspection: newInspection
              }));
              dispatch(addSuccess(transaction.name));
            });
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: transactionErrorMessage(transaction.name)
              }, transaction.name));
            });
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchSpaces = function fetchSpaces() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, spaces, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.Spaces.getAll()];

          case 1:
            response = _a.sent();
            spaces = response.data.reduce(function (spaces, space) {
              spaces[space.id] = space;
              return spaces;
            }, {});
            dispatch(setSpaces(spaces));
            return [3
            /*break*/
            , 3];

          case 2:
            error_3 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("refreshFailed"),
              noOverride: true
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchSpace = function fetchSpace(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var space, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.Spaces.getById(id)];

          case 1:
            space = _a.sent();
            dispatch(setSpace(space));
            return [3
            /*break*/
            , 3];

          case 2:
            error_4 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("failed")
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var inspectRoom = function inspectRoom(oldSpace, newInspection) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var transaction, attachmentIds, attachments, error_5;

      var _a, _b;

      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            transaction = {
              name: SpaceTransactionTypes.FINISH_INSPECTION,
              userId: newInspection.userId,
              spaceId: oldSpace.id,
              createdAt: newInspection.createdAt,
              meta: {
                result: newInspection.result,
                remark: newInspection.remark
              }
            };
            if (!!newInspection.result) return [3
            /*break*/
            , 5];
            attachmentIds = [];
            if (!(newInspection.attachments !== undefined && ((_a = newInspection.attachments) === null || _a === void 0 ? void 0 : _a.length) > 0)) return [3
            /*break*/
            , 4];
            _c.label = 1;

          case 1:
            _c.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , helpers.createAttachments(newInspection.attachments)];

          case 2:
            attachments = _c.sent();
            attachmentIds = attachments.map(function (a) {
              return a.id;
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_5 = _c.sent();
            return [3
            /*break*/
            , 4];

          case 4:
            transaction = __assign(__assign({}, transaction), {
              meta: __assign(__assign({}, transaction.meta), {
                tags: (_b = newInspection.tags) !== null && _b !== void 0 ? _b : [],
                attachmentIds: attachmentIds
              })
            });
            _c.label = 5;

          case 5:
            return [4
            /*yield*/
            , dispatch(handleInspectionTransaction({
              transaction: transaction,
              oldSpace: oldSpace,
              newInspection: newInspection
            }))];

          case 6:
            _c.sent();

            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createCustomSpace = function createCustomSpace(newSpace) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, space_1, error_6;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("customSpace");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading("add:customSpace"));
            return [4
            /*yield*/
            , services.Spaces.createCustomSpace(newSpace)];

          case 2:
            space_1 = _a.sent();
            batch(function () {
              dispatch(setSpace(space_1));
              dispatch(showNotification({
                type: "success",
                message: l.t("addSuccess", {
                  value: value
                })
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_6 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("addFailed", {
                  value: value
                })
              }, "add:customSpace"));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteSpace = function deleteSpace(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_7;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("customSpace");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading("delete:customSpace"));
            return [4
            /*yield*/
            , services.Spaces["delete"](id)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(removeSpace(id));
              dispatch(showNotification({
                type: "success",
                message: l.t("deleteSuccess", {
                  value: value
                })
              }, "delete:customSpace"));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_7 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("deleteFailed", {
                  value: value
                })
              }, "delete:customSpace"));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateCustomSpace = function updateCustomSpace(space, label, floor) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_8;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("customSpace");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading("update:customSpace"));
            return [4
            /*yield*/
            , services.Spaces.update(space.id, {
              label: label,
              floor: floor
            })];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(setSpace(__assign(__assign({}, space), {
                label: label,
                floor: floor
              })));
              dispatch(showNotification({
                type: "success",
                message: l.t("updateSuccess", {
                  value: value
                })
              }, "update:customSpace"));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_8 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("updateFailed", {
                  value: value
                })
              }, "update:customSpace"));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var assignArea = function assignArea(spaceIds, areaId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_1, error_9;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = spaceIds.length > 1 ? l.t("areas") : l.t("area");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , Promise.all(spaceIds.map(function (spaceId) {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4
                      /*yield*/
                      , services.Spaces.update(spaceId, {
                        areaId: areaId
                      })];

                    case 1:
                      return [2
                      /*return*/
                      , _a.sent()];
                  }
                });
              });
            }))];

          case 2:
            response_1 = _a.sent();
            batch(function () {
              response_1.forEach(function (res) {
                dispatch(setSpace(res));
              });
              dispatch(showNotification({
                type: "success",
                message: l.t("assignSuccess", {
                  value: value
                })
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_9 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("assignFailed", {
                value: value
              })
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var unassignArea = function unassignArea(spaceIds) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_2, error_10;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = spaceIds.length > 1 ? l.t("areas") : l.t("area");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , Promise.all(spaceIds.map(function (spaceId) {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4
                      /*yield*/
                      , services.Spaces.update(spaceId, {
                        areaId: ""
                      })];

                    case 1:
                      return [2
                      /*return*/
                      , _a.sent()];
                  }
                });
              });
            }))];

          case 2:
            response_2 = _a.sent();
            batch(function () {
              response_2.forEach(function (res) {
                dispatch(setSpace(res));
              });
              dispatch(showNotification({
                type: "success",
                message: l.t("unassignSuccess", {
                  value: value,
                  count: spaceIds.length
                })
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_10 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("unassignFailed", {
                value: value
              })
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};