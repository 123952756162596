var _a;

import { __assign } from "tslib";
import { SpaceTransactionTypes } from "../../types/transaction";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { isRoomSpace } from "../../helpers";
var spaceAdapter = createEntityAdapter();

var initialState = __assign({
  selectedId: undefined
}, spaceAdapter.getInitialState());

var spaceSlice = createSlice({
  name: "spaces",
  initialState: initialState,
  reducers: {
    setSpaces: spaceAdapter.setAll,
    setSpace: spaceAdapter.upsertOne,
    removeSpace: spaceAdapter.removeOne,
    setSelectedSpace: function setSelectedSpace(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    },
    commitInspectionTransaction: function commitInspectionTransaction(state, _a) {
      var _b = _a.payload,
          transaction = _b.transaction,
          newInspection = _b.newInspection;
      var space = state.entities[transaction.spaceId];
      if (!isRoomSpace(space)) return;
      space.room.inspected = true;
      space.room.clean = transaction.meta.result; // remove lastInspection if exist's

      if (newInspection.remark === undefined) {
        space.room.lastInspection = undefined;
        return;
      }

      space.room.lastInspection = __assign(__assign({}, newInspection), {
        remark: newInspection.remark
      });
    },
    commitSpaceTransaction: function commitSpaceTransaction(state, _a) {
      var payload = _a.payload;
      payload.forEach(function (action) {
        var space = state.entities[action.spaceId];
        if (!isRoomSpace(space)) return;
        state.entities[action.spaceId] = __assign(__assign({}, space), {
          room: roomStateByTransaction(space.room, action)
        });
      });
    }
  }
});
export var setSpace = (_a = spaceSlice.actions, _a.setSpace),
    setSpaces = _a.setSpaces,
    removeSpace = _a.removeSpace,
    commitSpaceTransaction = _a.commitSpaceTransaction,
    commitInspectionTransaction = _a.commitInspectionTransaction,
    setSelectedSpace = _a.setSelectedSpace;
export var spaceSelectors = spaceAdapter.getSelectors();

var assignTask = function assignTask(room, action) {
  var _a;

  var newTasks = (_a = room === null || room === void 0 ? void 0 : room.tasks) !== null && _a !== void 0 ? _a : [];

  if (action.name === SpaceTransactionTypes.ASSIGN_SPACE && action.taskId) {
    // Check if it's not already added (needed for socket + local changes on web)
    if (!newTasks.some(function (task) {
      return task.id === action.taskId;
    })) {
      newTasks.push({
        id: action.taskId
      });
    }
  } else {
    newTasks = newTasks.filter(function (task) {
      return task.id !== action.taskId;
    });
  }

  return __assign(__assign({}, room), {
    tasks: newTasks
  });
};

var assignUser = function assignUser(room, action) {
  var newAssignments = [];

  if (action.name === SpaceTransactionTypes.ASSIGN_SPACE) {
    newAssignments.push({
      id: action.userId,
      active: false
    });
  } else {
    newAssignments = newAssignments.filter(function (ass) {
      return ass.id !== action.userId;
    });
  }

  return __assign(__assign({}, room), {
    users: newAssignments
  });
};

var roomStateByTransaction = function roomStateByTransaction(room, action) {
  var _a, _b;

  switch (action.name) {
    // Clean space
    case SpaceTransactionTypes.START_CLEANING:
    case SpaceTransactionTypes.STOP_CLEANING:
    case SpaceTransactionTypes.FINISH_CLEANING:
      return __assign(__assign({}, room), {
        clean: action.name === SpaceTransactionTypes.FINISH_CLEANING,
        users: room === null || room === void 0 ? void 0 : room.users.map(function (_a) {
          var id = _a.id,
              active = _a.active;
          return id === action.userId ? {
            active: action.name === SpaceTransactionTypes.START_CLEANING,
            id: id
          } : {
            active: active,
            id: id
          };
        })
      });

    case SpaceTransactionTypes.SKIP_CLEANING:
      var doNotDisturb = (_a = action.meta) === null || _a === void 0 ? void 0 : _a.doNotDisturb;

      if (doNotDisturb !== undefined) {
        return __assign(__assign({}, room), {
          doNotDisturb: doNotDisturb,
          users: room === null || room === void 0 ? void 0 : room.users.map(function (_a) {
            var id = _a.id;
            return {
              active: false,
              id: id
            };
          })
        });
      }

      var freshTowels = (_b = action.meta) === null || _b === void 0 ? void 0 : _b.freshTowels;

      if (freshTowels !== undefined) {
        return __assign(__assign({}, room), {
          freshTowels: freshTowels,
          users: room === null || room === void 0 ? void 0 : room.users.map(function (_a) {
            var id = _a.id;
            return {
              active: false,
              id: id
            };
          })
        });
      }

      return room;
    // Assign space

    case SpaceTransactionTypes.ASSIGN_SPACE:
    case SpaceTransactionTypes.UNASSIGN_SPACE:
      if (action.userId) {
        return assignUser(room, action);
      }

      if (action.taskId) {
        return assignTask(room, action);
      }

      return room;

    default:
      return room;
  }
};

export default spaceSlice;