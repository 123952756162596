export default {
  "delete": "{{value}} deleted!",
  deleteSuccess: "{{value}} has been deleted successfully.",
  deleteSuccess_plural: "{{value}} have been deleted successfully.",
  deleteFailed: "{{value}} could not be deleted.",
  assign: "Assigned!",
  assignSuccess: "{{value}} has been assigned successfully.",
  assignFailed: "{{value}} could not be assigned.",
  unassign: "Unassigned!",
  unassignSuccess: "{{value}} has been unassigned successfully.",
  unassignSuccess_plural: "{{value}} have been unassigned successfully.",
  unassignFailed: "{{value}} could not be unassigned.",
  roomCleaned: "Room is cleaned!",
  finishCleaningFailed: "Can't change room to clean",
  startCleaningFailed: "Failed to start with cleaning",
  stopCleaningFailed: "Failed to stop with cleaning",
  finishInspectionFailed: "Room inspection failed",
  issueStatusUpdateFailed: "Updating task status failed",
  issueStatusUpdateSuccess: "Updating task status succeeded",
  add: "{{value}} added!",
  addSuccess: "{{value}} has been added successfully.",
  addSuccess_plural: "{{value}} have been added successfully.",
  addFailed: "{{value}} could not be added.",
  update: "{{value}} updated!",
  updateSuccess: "{{value}} has been updated successfully.",
  updateMessage_plural: "{{value}} have been updated successfully.",
  updateFailed: "{{value}} could not be updated.",
  refresh: "Refreshed {{value}}!",
  refreshFailed: "Refreshing data failed!",
  sessionExpired: "Session expired, please log in again.",
  "switch": "Switched to {{value}}",
  switchSuccess: "Successfully switched to {{value}}!",
  switchFailed: "Could not switch to {{value}}.",
  switchNotificationLanguageFailed: "Could not change notification language.",
  approveEOD: "Approved!",
  approveEODSuccess: "The end of day has been approved.",
  approveEODMFailed: "Failed to approve.",
  weekOverviewSend: "Week overview has been sent!",
  weekOverviewSendSuccess: "Week overview has been sent successfully.",
  weekOverviewSendFailed: "Failed to send week overview.",
  failed: "Failed!",
  noInternet: "No Internet",
  noInternetMsg: "No internet connection, please check the Wi-Fi status and if it's turned on",
  property: "Property",
  role: "Role",
  user: "User",
  space: "Space",
  customSpace: "Custom space",
  area: "Area",
  areas: "Area",
  remark: "Remark",
  endOfDay: "End of day",
  photo: "Photo",
  photos: "Photos",
  rateType: "Rate type",
  task: "Task",
  tasks: "Tasks",
  language: "Language",
  createUserFailed: "Attention: it's not possible to use the same e-mail for multiple users / hotels.",
  hkStatistics: {
    notAvailable: "Statistics are not (yet) available",
    atStart: "Start",
    currently: "Currently",
    departures: "Departures",
    carriedOver: "Carried over",
    dirtyVacant: "All vacant/dirty rooms",
    stayovers: "Stayovers",
    arrivals: "Arrivals",
    roomsCleaned: "Rooms cleaned",
    suitesCleaned: "Suites cleaned"
  },
  hcsNotification: {
    heading: {
      poor: "Unable to connect to IDPMS",
      bad: "Unable to connect to IDPMS for a longer time"
    },
    content: {
      poor: "The room statuses may not be up-to-date due to a connection problem with IDPMS. Ask the reception to create a case with Amadeus (hospitality.support@amadeus.com) with the subject: \"Hybrid Cloud Service is down for Hotel {{propertyName}}\"",
      bad: "The room statuses are not up-to-date due to a connection problem with IDPMS. Ask the front desk to create a case with Amadeus (hospitality.support@amadeus.com) with the subject: \"Hybrid Cloud Service is down for Hotel {{propertyName}}\""
    }
  }
};