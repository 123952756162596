var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var dayReportAdapter = createEntityAdapter();

var initialState = __assign({
  loading: false,
  error: false,
  current: undefined,
  meta: {
    totals: {
      noShow: 0,
      cleaned: 0,
      carriedOver: 0,
      carriedOverFromYesterday: 0,
      doNotDisturb: 0,
      dayUse: 0,
      green: 0,
      orange: 0,
      sold: 0
    },
    perRate: {}
  },
  week: {},
  reportExtras: {}
}, dayReportAdapter.getInitialState());

var dayReportSlice = createSlice({
  name: "dayReport",
  initialState: initialState,
  reducers: {
    setDayReports: function setDayReports(state, _a) {
      var payload = _a.payload;
      dayReportAdapter.setAll(state, payload.data);
      state.meta = payload.meta;
    },
    setWeek: function setWeek(state, _a) {
      var payload = _a.payload;
      state.meta = payload.meta;
      state.week = payload.data.reduce(function (allReports, dayReport) {
        var _a;

        return __assign(__assign({}, allReports), (_a = {}, _a[dayReport.id] = dayReport, _a));
      }, {});
    },
    setDayReport: function setDayReport(state, action) {
      state.current = action.payload;
      state.error = false;
    },
    setLoading: function setLoading(state, action) {
      state.loading = action.payload;
    },
    setError: function setError(state, action) {
      state.error = action.payload;
    }
  }
});
export var setDayReports = (_a = dayReportSlice.actions, _a.setDayReports),
    setDayReport = _a.setDayReport,
    setWeek = _a.setWeek,
    setLoading = _a.setLoading,
    setError = _a.setError;
export default dayReportSlice;