import { useEffect } from "react";
import { fetchProperty } from "../store/property/propertyThunk";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProperty } from "../store/property/propertySelector";
import l from "../locale";

var useHcsSyncStateNotification = function useHcsSyncStateNotification() {
  var dispatch = useDispatch();
  var currentProperty = useSelector(selectCurrentProperty);
  useEffect(function () {
    if (!currentProperty) return;

    var intervalCallback = function intervalCallback() {
      dispatch(fetchProperty(currentProperty.id));
    };

    var intervalTimeInSeconds = 30;
    var interval = setInterval(intervalCallback, intervalTimeInSeconds * 1000);
    return function () {
      return clearInterval(interval);
    };
  }, [currentProperty]);
  if (!currentProperty) return;
  var hcsSyncState = currentProperty.hcsSyncState;
  var showNotification = hcsSyncState !== "good";
  if (!showNotification) return;
  return {
    type: hcsSyncState === "bad" ? "negative" : "warning",
    heading: l.t("hcsNotification.heading.".concat(hcsSyncState)),
    content: l.t("hcsNotification.content.".concat(hcsSyncState), {
      propertyName: currentProperty.name
    })
  };
};

export default useHcsSyncStateNotification;