import { __assign, __awaiter, __generator } from "tslib";
import { batch } from "react-redux";
import services from "../../services/api";
import l from "../../locale";
import { showNotification } from "../ui/uiThunk";
import { setDayReport, setDayReports, setWeek, setLoading, setError } from "./dayReportSlice";
export var fetchDayReport = function fetchDayReport(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.DayReports.getById(id)];

          case 1:
            response = _a.sent();
            dispatch(setDayReport(response));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            dispatch(setError(true));
            return [3
            /*break*/
            , 3];

          case 3:
            dispatch(setLoading(false));
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchDayReportByDate = function fetchDayReportByDate(date) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            dispatch(setLoading(true));
            return [4
            /*yield*/
            , services.DayReports.getByDate(date)];

          case 1:
            response = _a.sent();
            dispatch(setDayReport(response));
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            dispatch(setError(true));
            return [3
            /*break*/
            , 3];

          case 3:
            dispatch(setLoading(false));
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchDayReports = function fetchDayReports(params) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, dayReports, meta, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 5,, 6]);

            return [4
            /*yield*/
            , services.DayReports.getPage(__assign(__assign({}, params), {
              page: 1
            }))];

          case 1:
            response = _a.sent();
            dayReports = response.data;
            _a.label = 2;

          case 2:
            if (!(response.meta.next !== null)) return [3
            /*break*/
            , 4];
            return [4
            /*yield*/
            , services.DayReports.getPage(__assign(__assign({}, params), {
              page: response.meta.next
            }))];

          case 3:
            response = _a.sent();
            dayReports.push.apply(dayReports, response.data);
            return [3
            /*break*/
            , 2];

          case 4:
            meta = {
              perRate: response.meta.perRate,
              totals: response.meta.totals
            };

            if (params.start) {
              dispatch(setWeek({
                data: dayReports,
                meta: meta
              }));
              return [2
              /*return*/
              ];
            }

            dispatch(setDayReports({
              data: dayReports,
              meta: meta
            }));
            return [3
            /*break*/
            , 6];

          case 5:
            error_3 = _a.sent();
            return [3
            /*break*/
            , 6];

          case 6:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateDayReport = function updateDayReport(id, dayReport) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_1, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("endOfDay");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.DayReports.update(id, dayReport)];

          case 2:
            response_1 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                message: l.t("updateSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(setDayReport(response_1));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _a.sent();
            dispatch(showNotification({
              message: l.t("updateFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteRemark = function deleteRemark(dayReport, auditId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, audits_1, error_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("remark");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.DayReports.deleteRemark(dayReport.id, auditId)];

          case 2:
            _a.sent();

            audits_1 = dayReport.audits.filter(function (storedAudit) {
              return storedAudit.id !== auditId;
            });
            batch(function () {
              dispatch(showNotification({
                message: l.t("deleteSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(setDayReport(__assign(__assign({}, dayReport), {
                audits: audits_1
              })));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_5 = _a.sent();
            dispatch(showNotification({
              message: l.t("deleteFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var approveDayReport = function approveDayReport(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response_2, error_6;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.DayReports.approve(id)];

          case 1:
            response_2 = _a.sent();
            batch(function () {
              dispatch(setDayReport(response_2));
              dispatch(showNotification({
                message: l.t("approveEODSuccess"),
                type: "success"
              }));
            });
            return [3
            /*break*/
            , 3];

          case 2:
            error_6 = _a.sent();
            dispatch(showNotification({
              message: l.t("approveEODMFailed"),
              type: "error"
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deliverWeekReport = function deliverWeekReport(date) {
  return function () {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_7;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.DayReports.deliver(date)];

          case 1:
            _a.sent();

            showNotification({
              message: l.t("weekOverviewSendSuccess"),
              type: "success"
            });
            return [3
            /*break*/
            , 3];

          case 2:
            error_7 = _a.sent();
            showNotification({
              message: l.t("weekOverviewSendFailed"),
              type: "error"
            });
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};