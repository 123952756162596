var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
var userAdapter = createEntityAdapter();

var initialState = __assign({
  current: undefined,
  selectedRole: undefined,
  selectedPropertyId: undefined,
  selectedId: undefined
}, userAdapter.getInitialState());

var userSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setUsers: function setUsers(state, _a) {
      var payload = _a.payload;
      userAdapter.setAll(state, payload);
    },
    setUser: function setUser(state, _a) {
      var _b;

      var payload = _a.payload;
      if (((_b = state.current) === null || _b === void 0 ? void 0 : _b.id) === payload.id) state.current = payload;
      userAdapter.upsertOne(state, payload);
    },
    setCurrentUser: function setCurrentUser(state, _a) {
      var payload = _a.payload;
      state.current = payload;
    },
    setSelectedRole: function setSelectedRole(state, _a) {
      var payload = _a.payload;
      state.selectedRole = payload;
    },
    setSelectedPropertyId: function setSelectedPropertyId(state, _a) {
      var payload = _a.payload;
      state.selectedPropertyId = payload;
    },
    setSelectedUser: function setSelectedUser(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    },
    resetStore: function resetStore() {
      return initialState;
    },
    removeUser: function removeUser(state, _a) {
      var payload = _a.payload;
      userAdapter.removeOne(state, payload);
    }
  }
});
export var setUser = (_a = userSlice.actions, _a.setUser),
    setUsers = _a.setUsers,
    setCurrentUser = _a.setCurrentUser,
    setSelectedPropertyId = _a.setSelectedPropertyId,
    setSelectedRole = _a.setSelectedRole,
    setSelectedUser = _a.setSelectedUser,
    removeUser = _a.removeUser,
    resetStore = _a.resetStore;
export var userSelectors = userAdapter.getSelectors();
export default userSlice;