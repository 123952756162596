var _a;

import { createSelector } from "reselect";
import { rateTypeAdapter } from "./rateTypeSlice";

var selectSelf = function selectSelf(state) {
  return state.rateType;
};

export var selectRoomCodeEntities = createSelector(selectSelf, function (rateType) {
  return Object.values(rateType.entities).reduce(function (result, entity) {
    entity === null || entity === void 0 ? void 0 : entity.roomCodes.forEach(function (roomCode) {
      result[roomCode] = {
        roomCode: roomCode,
        rateType: {
          id: entity.id,
          name: entity.name
        }
      };
    });
    return result;
  }, {});
});
export var selectAllRoomCodes = createSelector(selectSelf, function (rateType) {
  return Object.values(rateType.entities).reduce(function (result, entity) {
    entity === null || entity === void 0 ? void 0 : entity.roomCodes.forEach(function (roomCode) {
      result.push({
        roomCode: roomCode,
        rateType: {
          id: entity.id,
          name: entity.name
        }
      });
    });
    return result;
  }, []).sort(function (a, b) {
    return a.rateType.name.localeCompare(b.rateType.name);
  });
});
export var selectRateTypeEntities = (_a = rateTypeAdapter.getSelectors(), _a.selectEntities),
    selectRateTypeById = _a.selectById,
    selectAllRateTypes = _a.selectAll;
export default {
  selectRateTypeEntities: selectRateTypeEntities,
  selectRateTypeById: selectRateTypeById,
  selectAllRateTypes: selectAllRateTypes,
  selectRoomCodeEntities: selectRoomCodeEntities,
  selectAllRoomCodes: selectAllRoomCodes
};