import { createSelector } from "@reduxjs/toolkit";

var selectSelf = function selectSelf(state) {
  return state.ui;
};

export var selectErrors = createSelector(selectSelf, function (_a) {
  var errors = _a.errors;
  return errors;
});
export var selectLoading = createSelector(selectSelf, function (_a) {
  var loading = _a.loading;
  return loading;
});
export var selectSuccess = createSelector(selectSelf, function (_a) {
  var success = _a.success;
  return success;
});
export var selectPopup = createSelector(function (state) {
  return state.ui.web.popUp;
}, function (popUp) {
  return popUp;
});
export var isLoadingAction = createSelector(selectSelf, function (_, actionType) {
  return actionType;
}, function (_a, actionType) {
  var loading = _a.loading;
  return loading.includes(actionType);
});
export var selectRoomToggles = createSelector(function (state) {
  return state.ui.roomToggles;
}, function (roomToggles) {
  return roomToggles;
});
export var makeSelectRoomToggleById = function makeSelectRoomToggleById() {
  return createSelector(function (state) {
    return state.ui.roomToggles;
  }, function (_, spaceId) {
    return spaceId;
  }, function (roomToggles, spaceId) {
    return spaceId in roomToggles;
  });
};
export var selectRoomFilters = createSelector(function (state) {
  return state.ui.roomFilters;
}, function (roomFilters) {
  return roomFilters;
});
export var actionSucceeded = createSelector(selectSelf, function (_, actionType) {
  return actionType;
}, function (_a, actionType) {
  var success = _a.success;
  return success.includes(actionType);
});
export var actionFailed = createSelector(selectSelf, function (_, actionType) {
  return actionType;
}, function (_a, actionType) {
  var errors = _a.errors;
  return errors.includes(actionType);
});
export var selectSidebarView = createSelector(selectSelf, function (_a) {
  var sidebarView = _a.sidebarView;
  return sidebarView;
});
export var selectRequiredDataState = createSelector(selectSelf, function (_a) {
  var requiredDataState = _a.requiredDataState;
  return requiredDataState;
});