var _a;

import { createSelector } from "@reduxjs/toolkit";
import { transactionAdapter } from "./transactionSlice";

var selectSelf = function selectSelf(state) {
  return state.transaction;
};

export var selectTransactionMeta = createSelector(selectSelf, function (_a) {
  var meta = _a.meta;
  return meta !== null && meta !== void 0 ? meta : {
    count: 0,
    last: 1,
    next: null,
    prev: null,
    page: 1,
    pages: 1
  };
});
export var setTransactions = (_a = transactionAdapter.getSelectors(), _a.selectAll),
    selectAllTransactions = _a.selectAll;