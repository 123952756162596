var _a, _b;

import { __assign, __spreadArray } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IssueState as IssueStates } from "./types";
import { IssueTransactionTypes } from "../../types/transaction";
var issueAdapter = createEntityAdapter();

var initialState = __assign({
  selectedId: undefined,
  meta: undefined
}, issueAdapter.getInitialState());

var issueChangesByWorkTransaction = function issueChangesByWorkTransaction(transaction, prevIssue) {
  var _a, _b, _c, _d;

  var userId = transaction.userId;

  var workLogs = __spreadArray([], prevIssue === null || prevIssue === void 0 ? void 0 : prevIssue.workLogs, true);

  switch (transaction.name) {
    case IssueTransactionTypes.ASSIGN:
      {
        if (userId !== undefined) return {
          users: [{
            id: userId
          }]
        };
        return {
          users: []
        };
      }

    case IssueTransactionTypes.UNASSIGN:
      {
        var users = prevIssue.users.filter(function (user) {
          return user.id !== transaction.userId;
        });
        return {
          users: users
        };
      }

    case IssueTransactionTypes.START_WORK:
      {
        var users = __spreadArray([], prevIssue.users, true);

        if (userId !== undefined) {
          workLogs.unshift({
            userId: userId,
            remark: (_a = transaction.meta) === null || _a === void 0 ? void 0 : _a.remark,
            prevState: prevIssue.state,
            nextState: IssueStates.IN_PROGRESS,
            createdAt: transaction.createdAt,
            attachments: []
          });
          if (!users.some(function (user) {
            return user.id === userId;
          })) users.push({
            id: userId
          });
        }

        return {
          state: stateByTransactionType[transaction.name],
          users: users,
          workLogs: workLogs
        };
      }

    case IssueTransactionTypes.STOP_WORK:
      {
        var nextState = (_b = transaction.meta) === null || _b === void 0 ? void 0 : _b.nextState;
        var state = stateByTransactionType[transaction.name];

        if (userId !== undefined) {
          state = nextState !== null && nextState !== void 0 ? nextState : IssueStates.OPEN;
          workLogs.unshift({
            userId: userId,
            remark: (_c = transaction.meta) === null || _c === void 0 ? void 0 : _c.remark,
            prevState: prevIssue.state,
            nextState: state,
            createdAt: transaction.createdAt,
            attachments: []
          });
        }

        return {
          state: state,
          workLogs: workLogs
        };
      }

    case IssueTransactionTypes.FINISH_WORK:
      {
        if (userId !== undefined) workLogs.unshift({
          userId: userId,
          remark: (_d = transaction.meta) === null || _d === void 0 ? void 0 : _d.remark,
          prevState: prevIssue.state,
          nextState: IssueStates.DONE,
          createdAt: transaction.createdAt,
          attachments: []
        });
        return {
          state: stateByTransactionType[transaction.name],
          workLogs: workLogs
        };
      }

    default:
      return {};
  }
};

var stateByTransactionType = (_a = {}, _a[IssueTransactionTypes.START_WORK] = IssueStates.IN_PROGRESS, _a[IssueTransactionTypes.STOP_WORK] = IssueStates.OPEN, _a[IssueTransactionTypes.FINISH_WORK] = IssueStates.DONE, _a);
var issueSlice = createSlice({
  name: "issues",
  initialState: initialState,
  reducers: {
    setIssues: issueAdapter.setAll,
    setIssue: issueAdapter.upsertOne,
    addIssue: issueAdapter.addOne,
    removeIssue: issueAdapter.removeOne,
    setSelectedIssueId: function setSelectedIssueId(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    },
    setPaginationMeta: function setPaginationMeta(state, _a) {
      var payload = _a.payload;
      state.meta = payload;
    },
    deleteAttachment: function deleteAttachment(state, _a) {
      var payload = _a.payload;
      var issue = state.entities[payload.issueId];
      if (issue === undefined) return;
      issue.attachments = issue.attachments.filter(function (a) {
        return a.id != payload.attachment.id;
      });
    },
    commitAttachments: function commitAttachments(state, _a) {
      var payload = _a.payload;
      var issueId = payload.issueId,
          attachments = payload.attachments;
      var issue = state.entities[issueId];
      if (issue === undefined) return;

      if (issue.attachments === undefined) {
        issue.attachments = attachments;
        return;
      }

      issue.attachments = issue.attachments.concat(attachments);
    },
    commitIssueTransaction: function commitIssueTransaction(state, _a) {
      var payload = _a.payload;
      var updatedIssues = [];
      payload.forEach(function (_a) {
        var transaction = _a.transaction,
            prevIssue = _a.prevIssue;
        var update = {
          id: transaction.issueId,
          changes: {}
        };
        update.changes = issueChangesByWorkTransaction(transaction, prevIssue);
        updatedIssues.push(update);
      });
      issueAdapter.updateMany(state, updatedIssues);
    }
  }
});
export var setIssue = (_b = issueSlice.actions, _b.setIssue),
    setIssues = _b.setIssues,
    setSelectedIssueId = _b.setSelectedIssueId,
    addIssue = _b.addIssue,
    commitIssueTransaction = _b.commitIssueTransaction,
    removeIssue = _b.removeIssue,
    commitAttachments = _b.commitAttachments,
    deleteAttachment = _b.deleteAttachment,
    setPaginationMeta = _b.setPaginationMeta;
export var issueSelectors = issueAdapter.getSelectors();
export default issueSlice;