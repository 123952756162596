import { __awaiter, __generator } from "tslib";
import services from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import { setTransactionMeta, setTransactions } from "./transactionSlice";
import l from "../../locale";
export var fetchTransactionsByPage = function fetchTransactionsByPage(page, spaceLabel) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var _a, data, meta, error_1;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _b.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.Transactions.getByPage({
              page: page,
              spaceLabel: spaceLabel
            })];

          case 1:
            _a = _b.sent(), data = _a.data, meta = _a.meta;
            dispatch(setTransactions(data));
            dispatch(setTransactionMeta(meta));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _b.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("refreshFailed"),
              noOverride: true
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};