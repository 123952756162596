export default {
  sessionExpired: "Sitzung abgelaufen, bitte melden Sie sich erneut an.",
  hcsNotification: {
    heading: {
      poor: "Keine Verbindung zu IDPMS möglich",
      bad: "Keine Verbindung zu IDPMS für längere Zeit möglich"
    },
    content: {
      poor: "Der Zimmerstatus ist aufgrund eines Verbindungsproblems mit IDPMS möglicherweise nicht aktuell. Bitten Sie die Rezeption, einen fall bei Amadeus (hospitality.support@amadeus.com) mit dem Betreff \"Hybrid Cloud Service is down for Hotel {{propertyName}}\"",
      bad: "Die Zimmerstatus sind aufgrund eines Verbindungsproblems mit IDPMS nicht aktuell. Bitten Sie die Rezeption, einen fall bei Amadeus (hospitality.support@amadeus.com) mit dem Betreff \"Hybrid Cloud Service is down for Hotel {{propertyName}}\""
    }
  }
};