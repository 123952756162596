import { __awaiter, __generator } from "tslib";
import { removeUser, resetStore, setCurrentUser, setSelectedPropertyId, setSelectedRole, setSelectedUser, setUser, setUsers } from "./userSlice";
import helpers, { Auth } from "../../services/helpers";
import { batch } from "react-redux";
import api from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import l from "../../locale";
import { setRequiredDataState } from "../ui/uiSlice";
import { setSpaces } from "../space/spaceSlice";
import { setProperties } from "../property/propertySlice";
import { setRateTypes } from "../rateType/rateTypeSlice";
import { setIssues } from "../issue/issueSlice";
import { setAreas } from "../area/areaSlice";
import { setTasks } from "../task/taskSlice";
import { createKeyValueObject } from "../helpers";
import { fetchAllIssues } from "../issue/issueHelpers";
import Bugsnag from "../../services/bugsnag";
export var signInUser = function signInUser(_a) {
  var email = _a.email,
      password = _a.password,
      userId = _a.userId;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var signedIn, auth;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            signedIn = false;
            if (!(email !== undefined && password !== undefined)) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , api.Auth.signInWithEmail(email, password)];

          case 1:
            signedIn = _a.sent();
            return [3
            /*break*/
            , 4];

          case 2:
            if (!(userId !== undefined)) return [3
            /*break*/
            , 4];
            return [4
            /*yield*/
            , api.Auth.signInWithQr(userId)];

          case 3:
            signedIn = _a.sent();
            _a.label = 4;

          case 4:
            if (!signedIn) {
              throw Error("invalid_auth");
            }

            return [4
            /*yield*/
            , helpers.Auth.get()];

          case 5:
            auth = _a.sent();
            if (!auth) return [2
            /*return*/
            ];
            dispatch(fetchRequiredData());
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchRequiredData = function fetchRequiredData() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var auth_1, user_1, _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, e_1;

      return __generator(this, function (_x) {
        switch (_x.label) {
          case 0:
            _x.trys.push([0, 12,, 13]);

            return [4
            /*yield*/
            , helpers.Auth.get()];

          case 1:
            auth_1 = _x.sent();
            if (!auth_1) return [2
            /*return*/
            ];
            dispatch(setRequiredDataState("loading"));
            return [4
            /*yield*/
            , api.Users.getById(auth_1.currentUserId)];

          case 2:
            user_1 = _x.sent();
            if (process.env.JEST_WORKER_ID === undefined) Bugsnag.setUser(String(auth_1.currentUserId));
            batch(function () {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  dispatch(setSelectedPropertyId(auth_1.property));
                  dispatch(setSelectedRole(auth_1.role));
                  dispatch(setCurrentUser(user_1));
                  dispatch(setUser(user_1));
                  return [2
                  /*return*/
                  ];
                });
              });
            });
            _b = (_a = Promise).all;
            _c = dispatch;
            _d = setTasks;
            return [4
            /*yield*/
            , api.Tasks.getAll()];

          case 3:
            _e = [_c.apply(void 0, [_d.apply(void 0, [_x.sent().data])])];
            _f = dispatch;
            _g = setAreas;
            return [4
            /*yield*/
            , api.Areas.getAll()];

          case 4:
            _e = _e.concat([_f.apply(void 0, [_g.apply(void 0, [_x.sent().data])])]);
            _h = dispatch;
            _j = setSpaces;
            _k = createKeyValueObject;
            return [4
            /*yield*/
            , api.Spaces.getAll()];

          case 5:
            _e = _e.concat([_h.apply(void 0, [_j.apply(void 0, [_k.apply(void 0, [_x.sent().data])])])]);
            _l = dispatch;
            _m = setProperties;
            return [4
            /*yield*/
            , api.Property.getAll()];

          case 6:
            _e = _e.concat([_l.apply(void 0, [_m.apply(void 0, [_x.sent().data])])]);
            _o = dispatch;
            _p = setRateTypes;
            return [4
            /*yield*/
            , api.RateTypes.getAll()];

          case 7:
            _e = _e.concat([_o.apply(void 0, [_p.apply(void 0, [_x.sent().data])])]);
            _q = false;
            if (!_q) return [3
            /*break*/
            , 9];
            _r = dispatch;
            _s = setIssues;
            _t = createKeyValueObject;
            return [4
            /*yield*/
            , fetchAllIssues()];

          case 8:
            _q = _r.apply(void 0, [_s.apply(void 0, [_t.apply(void 0, [_x.sent()])])]);
            _x.label = 9;

          case 9:
            _e = _e.concat([_q]);
            _u = dispatch;
            _v = setSpaces;
            _w = createKeyValueObject;
            return [4
            /*yield*/
            , api.Spaces.getAll()];

          case 10:
            return [4
            /*yield*/
            , _b.apply(_a, [_e.concat([_u.apply(void 0, [_v.apply(void 0, [_w.apply(void 0, [_x.sent().data])])])])])];

          case 11:
            _x.sent();

            dispatch(setRequiredDataState("loaded"));
            return [3
            /*break*/
            , 13];

          case 12:
            e_1 = _x.sent();
            dispatch(setRequiredDataState("error"));
            return [3
            /*break*/
            , 13];

          case 13:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var signOut = function signOut(showError) {
  if (showError === void 0) {
    showError = false;
  }

  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2, 3, 4]);

            return [4
            /*yield*/
            , Auth.reset()];

          case 1:
            _a.sent();

            if (!showError) return [2
            /*return*/
            ];
            dispatch(showNotification({
              type: "error",
              message: l.t("sessionExpired")
            }));
            return [3
            /*break*/
            , 4];

          case 2:
            error_1 = _a.sent();
            return [3
            /*break*/
            , 4];

          case 3:
            dispatch(resetStore());
            dispatch(setRequiredDataState("void"));
            if (process.env.JEST_WORKER_ID === undefined) Bugsnag.setUser();
            return [7
            /*endfinally*/
            ];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var resetPassword = function resetPassword(email) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Users.resetPassword(email)];

          case 1:
            _a.sent();

            dispatch(showNotification({
              type: "success",
              message: "Email verstuurd met instructies."
            }));
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: "Email niet verstuurd, probeer later opnieuw."
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchUsers = function fetchUsers() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, users, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Users.getAll()];

          case 1:
            response = _a.sent();
            users = response.data.reduce(function (allUsers, user) {
              allUsers[user.id] = user;
              return allUsers;
            }, {});
            dispatch(setUsers(users));
            return [3
            /*break*/
            , 3];

          case 2:
            error_3 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var switchRoleOrProperty = function switchRoleOrProperty(_a) {
  var propertyId = _a.propertyId,
      role = _a.role;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, switched, auth_2, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = "";
            if (propertyId !== undefined) value = l.t("property");
            if (role !== undefined) value = l.t("role");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 7,, 8]);

            switched = false;
            if (!propertyId) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , api.Property["switch"](propertyId)];

          case 2:
            switched = _a.sent();
            _a.label = 3;

          case 3:
            if (!role) return [3
            /*break*/
            , 5];
            return [4
            /*yield*/
            , api.Users.switchRole(role)];

          case 4:
            switched = _a.sent();
            _a.label = 5;

          case 5:
            return [4
            /*yield*/
            , helpers.Auth.get()];

          case 6:
            auth_2 = _a.sent();
            if (auth_2 === null || !switched) throw new Error("error");
            batch(function () {
              return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  dispatch(setSelectedPropertyId(auth_2.property));
                  dispatch(setSelectedRole(auth_2.role));
                  dispatch(showNotification({
                    message: l.t("switchSuccess", {
                      value: value
                    }),
                    type: "success"
                  }));
                  return [2
                  /*return*/
                  ];
                });
              });
            });
            return [3
            /*break*/
            , 8];

          case 7:
            error_4 = _a.sent();
            dispatch(showNotification({
              message: l.t("switchFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 8];

          case 8:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchUser = function fetchUser(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var user_2, auth, currentUserId_1, error_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);

            return [4
            /*yield*/
            , api.Users.getById(id)];

          case 1:
            user_2 = _a.sent();
            return [4
            /*yield*/
            , helpers.Auth.get()];

          case 2:
            auth = _a.sent();
            currentUserId_1 = auth === null || auth === void 0 ? void 0 : auth.currentUserId;
            batch(function () {
              dispatch(setUser(user_2));
              if (currentUserId_1 === id) dispatch(setCurrentUser(user_2));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_5 = _a.sent();
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createUser = function createUser(user) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_1, error_6;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("user");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Users.create(user)];

          case 2:
            response_1 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                message: l.t("addSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(setUser(response_1));
              dispatch(setSelectedUser(response_1.id));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_6 = _a.sent();
            dispatch(showNotification({
              title: l.t("addFailed", {
                value: value
              }),
              message: l.t("createUserFailed"),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteUser = function deleteUser(userId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_7;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("user");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Users["delete"](userId)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(showNotification({
                message: l.t("deleteSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(removeUser(userId));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_7 = _a.sent();
            dispatch(showNotification({
              message: l.t("deleteFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateUser = function updateUser(updatedParams, userId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_2, error_8;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("user");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Users.update(userId, updatedParams)];

          case 2:
            response_2 = _a.sent();
            batch(function () {
              dispatch(setUser(response_2));
              dispatch(showNotification({
                message: l.t("updateSuccess", {
                  value: value
                }),
                type: "success"
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_8 = _a.sent();
            showNotification({
              message: l.t("updateFailed", {
                value: value
              }),
              type: "error"
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var switchLanguage = function switchLanguage(updatedParams, userId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var newLanguage, value, response_3, error_9;

      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            newLanguage = (_a = updatedParams.profile) === null || _a === void 0 ? void 0 : _a.language;

            if (l.language !== newLanguage && newLanguage !== undefined) {
              l.changeLanguage(newLanguage);
            }

            value = l.t("language");
            _b.label = 1;

          case 1:
            _b.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Users.update(userId, updatedParams)];

          case 2:
            response_3 = _b.sent();
            batch(function () {
              dispatch(setUser(response_3));
              dispatch(showNotification({
                message: l.t("switchSuccess", {
                  value: value
                }),
                type: "success"
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_9 = _b.sent();
            showNotification({
              message: l.t("switchFailed", {
                value: value
              }),
              type: "error"
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};