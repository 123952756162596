export var UserRoles;

(function (UserRoles) {
  UserRoles["ADMIN"] = "admin";
  UserRoles["HKTD_MANAGER"] = "manager"; //   HK

  UserRoles["HK_MEMBER"] = "hk_member";
  UserRoles["HK_MANAGER"] = "hk_manager"; //   TD

  UserRoles["TD_MEMBER"] = "td_member";
  UserRoles["TD_MANAGER"] = "td_manager";
  UserRoles["TD_CREATOR"] = "td_creator";
})(UserRoles || (UserRoles = {}));