import { createSelector } from "@reduxjs/toolkit";
import { getEditableRoles, getMostImportantRole } from "../../helpers";
import { UserRoles } from "./types";
import { userSelectors } from "./userSlice";
export var selectAllUsers = userSelectors.selectAll,
    selectTotalUsers = userSelectors.selectTotal,
    selectAllUserIds = userSelectors.selectIds,
    selectUserEntities = userSelectors.selectEntities,
    selectUserById = userSelectors.selectById;

var selectSelf = function selectSelf(state) {
  return state.user;
};

export var selectCurrentUser = createSelector(selectSelf, function (state) {
  return state === null || state === void 0 ? void 0 : state.current;
});
export var selectCurrentUserId = createSelector(selectSelf, function (state) {
  return state.current ? state.current.id.toString() : undefined;
});
export var selectCurrentRole = createSelector(selectSelf, function (state) {
  return state.selectedRole;
});
export var selectCurrentPropertyId = createSelector(selectSelf, function (state) {
  return state.selectedPropertyId;
});
export var selectSelectedUserId = createSelector(selectSelf, function (data) {
  return data.selectedId;
});
export var selectSelectedUser = createSelector(selectSelf, function (state) {
  var id = state.selectedId;

  if (id) {
    return selectUserById(state, id);
  }

  return undefined;
});
export var selectEditableUsers = createSelector(function (state) {
  return state;
}, function (state) {
  var editableRoles = selectEditableRoles(state);
  if (editableRoles === undefined) return undefined;

  var _a = selectSelf(state),
      ids = _a.ids,
      entities = _a.entities;

  return ids.reduce(function (users, id) {
    var user = entities[id];
    var mostImportantRole = getMostImportantRole(user === null || user === void 0 ? void 0 : user.roles);

    if (user !== undefined && mostImportantRole !== undefined && (editableRoles === null || editableRoles === void 0 ? void 0 : editableRoles.includes(mostImportantRole))) {
      users.push(user);
    }

    return users;
  }, []);
});
export var selectEditableRoles = createSelector(selectSelf, function (state) {
  var role = state.selectedRole;
  if (role === undefined) return undefined;
  return getEditableRoles(role);
});
export var selectAllUsersByRoles = createSelector(selectSelf, function (_, roles) {
  return roles;
}, function (_a, roles) {
  var entities = _a.entities,
      ids = _a.ids;
  return ids.reduce(function (users, id) {
    var user = entities[id];

    if (user === null || user === void 0 ? void 0 : user.roles.some(function (role) {
      return roles.includes(role);
    })) {
      users.push(user);
    }

    return users;
  }, []);
});
export var selectUserEntitiesByRoles = createSelector(selectSelf, function (_, roles) {
  return roles;
}, function (_a, roles) {
  var entities = _a.entities,
      ids = _a.ids;
  return ids.reduce(function (users, id) {
    var user = entities[id];

    if (user === null || user === void 0 ? void 0 : user.roles.some(function (role) {
      return roles.includes(role);
    })) {
      users[user.id] = user;
    }

    return users;
  }, {});
});
export var selectHKMembers = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var hkMembers = [];
  ids.forEach(function (id) {
    var user = entities[id];
    if (user === undefined || !(user === null || user === void 0 ? void 0 : user.roles.includes(UserRoles.HK_MEMBER)) || (user === null || user === void 0 ? void 0 : user.roles.length) > 1) return;
    hkMembers.push(user);
  });
  return hkMembers;
});
export var selectHKMemberEntities = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var hkMembers = {};
  ids.forEach(function (id) {
    var user = entities[id];
    if (user === undefined || !(user === null || user === void 0 ? void 0 : user.roles.includes(UserRoles.HK_MEMBER)) || (user === null || user === void 0 ? void 0 : user.roles.length) > 1) return;
    hkMembers[user.id] = user;
  });
  return hkMembers;
});
export var selectTDMembers = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var tdMembers = [];
  ids.forEach(function (id) {
    var user = entities[id];
    if (user === undefined || !(user === null || user === void 0 ? void 0 : user.roles.includes(UserRoles.TD_MEMBER)) && !(user === null || user === void 0 ? void 0 : user.roles.includes(UserRoles.TD_MANAGER)) || (user === null || user === void 0 ? void 0 : user.roles.length) > 1) return;
    tdMembers.push(user);
  });
  return tdMembers;
});
export var selectTDMemberEntities = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var tdMembers = {};
  ids.forEach(function (id) {
    var user = entities[id];
    if (user === undefined || !(user === null || user === void 0 ? void 0 : user.roles.includes(UserRoles.TD_MEMBER)) && !(user === null || user === void 0 ? void 0 : user.roles.includes(UserRoles.TD_MANAGER)) || (user === null || user === void 0 ? void 0 : user.roles.length) > 1) return;
    tdMembers[user.id] = user;
  });
  return tdMembers;
});