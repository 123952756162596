export var CleaningTypes;

(function (CleaningTypes) {
  CleaningTypes["FULL"] = "full";
  CleaningTypes["SKIP"] = "skip";
  CleaningTypes["FRESH_TOWELS"] = "fresh_towels";
})(CleaningTypes || (CleaningTypes = {}));

export var IssueState;

(function (IssueState) {
  IssueState["OPEN"] = "open";
  IssueState["IN_PROGRESS"] = "in_progress";
  IssueState["ON_HOLD"] = "on_hold";
  IssueState["DONE"] = "done";
})(IssueState || (IssueState = {}));

export var IssueFilterTypes;

(function (IssueFilterTypes) {
  IssueFilterTypes["SEARCH_TEXT"] = "search_text";
  IssueFilterTypes["STATUS_TAB"] = "status_tab";
})(IssueFilterTypes || (IssueFilterTypes = {}));