import { AttachmentsTransactionTypes, IssueTransactionTypes, SpaceTransactionTypes } from "./types/transaction";
import { SpaceType } from "./store/space/types";
import { UserRoles } from "./store/user/types";
import l from "./locale";
export var capitalizeFirstLetter = function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export var isRoomSpace = function isRoomSpace(space) {
  return (space === null || space === void 0 ? void 0 : space.type) === SpaceType.ROOM;
};
export var isCustomSpace = function isCustomSpace(space) {
  return (space === null || space === void 0 ? void 0 : space.type) === SpaceType.CUSTOM;
};
export var getMostImportantRole = function getMostImportantRole(roles) {
  if (roles === undefined || roles.length === 0) return undefined;
  if (roles.length === 1) return roles[0];
  return [UserRoles.ADMIN, UserRoles.HKTD_MANAGER, UserRoles.HK_MANAGER, UserRoles.TD_MANAGER, UserRoles.HK_MEMBER, UserRoles.TD_MEMBER, UserRoles.TD_CREATOR].find(function (role) {
    return roles.includes(role);
  });
};
export var getEditableRoles = function getEditableRoles(role) {
  switch (role) {
    case UserRoles.ADMIN:
    case UserRoles.HKTD_MANAGER:
      return Object.values(UserRoles);

    case UserRoles.HK_MANAGER:
      return [UserRoles.HK_MEMBER, UserRoles.HK_MANAGER];

    case UserRoles.TD_MANAGER:
      // Also add td_creater when supported on app
      return [UserRoles.TD_MEMBER, UserRoles.TD_MANAGER];

    default:
      return undefined;
  }
};
export var transactionErrorMessage = function transactionErrorMessage(name) {
  switch (name) {
    case SpaceTransactionTypes.ASSIGN_SPACE:
      return l.t("assignFailed", {
        value: l.t("space").toLowerCase()
      });

    case SpaceTransactionTypes.UNASSIGN_SPACE:
      return l.t("unassignFailed", {
        value: l.t("space").toLowerCase()
      });

    case SpaceTransactionTypes.START_CLEANING:
      return l.t("startCleaningFailed");

    case SpaceTransactionTypes.STOP_CLEANING:
      return l.t("stopCleaningFailed");

    case SpaceTransactionTypes.FINISH_CLEANING:
      return l.t("finishCleaningFailed");

    case SpaceTransactionTypes.FINISH_INSPECTION:
      return l.t("finishInspectionFailed");

    case IssueTransactionTypes.START_WORK:
    case IssueTransactionTypes.FINISH_WORK:
    case IssueTransactionTypes.STOP_WORK:
      return l.t("issueStatusUpdateFailed");

    case IssueTransactionTypes.ASSIGN:
      return l.t("assignFailed", {
        value: l.t("issue").toLowerCase()
      });

    case AttachmentsTransactionTypes.ADD_ATTACHMENT:
      return l.t("addFailed", {
        value: l.t("photos").toLowerCase()
      });

    default:
      return l.t("failed");
  }
};
export var transactionSuccessMessage = function transactionSuccessMessage(name) {
  switch (name) {
    case SpaceTransactionTypes.ASSIGN_SPACE:
      return l.t("assignSuccess", {
        value: l.t("space").toLowerCase()
      });

    case SpaceTransactionTypes.UNASSIGN_SPACE:
      return l.t("unassignSuccess", {
        value: l.t("space").toLowerCase()
      });

    case SpaceTransactionTypes.START_CLEANING:
      return l.t("startCleaningSuccess");

    case SpaceTransactionTypes.STOP_CLEANING:
      return l.t("stopCleaningSuccess");

    case SpaceTransactionTypes.FINISH_CLEANING:
      return l.t("finishCleaningSuccess");

    case SpaceTransactionTypes.FINISH_INSPECTION:
      return l.t("finishInspectionSuccess");

    case IssueTransactionTypes.START_WORK:
    case IssueTransactionTypes.FINISH_WORK:
    case IssueTransactionTypes.STOP_WORK:
      return l.t("issueStatusUpdateSuccess");

    case IssueTransactionTypes.ASSIGN:
      return l.t("assignSuccess", {
        value: l.t("issue").toLowerCase()
      });

    case AttachmentsTransactionTypes.ADD_ATTACHMENT:
      return l.t("addSuccess", {
        value: l.t("photos").toLowerCase()
      });

    default:
      return l.t("updateSuccess", {
        value: l.t("task")
      });
  }
};