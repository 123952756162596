import { __assign, __awaiter, __generator } from "tslib";
import api from '../../services/api';
import l from '../../locale';
import { batch } from 'react-redux';
import { showNotification } from '../ui/uiThunk';
import { setProperties, setProperty } from './propertySlice';
export var fetchProperty = function fetchProperty(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Property.getById(id)];

          case 1:
            response = _a.sent();
            dispatch(setProperty(response));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchProperties = function fetchProperties() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Property.getAll()];

          case 1:
            response = _a.sent();
            dispatch(setProperties(response.data));
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateProperty = function updateProperty(oldProperty, params) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t('property');
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , api.Property.update(oldProperty.id, params)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(setProperty(__assign(__assign({}, oldProperty), {
                settings: __assign(__assign({}, oldProperty.settings), params)
              })));
              dispatch(showNotification({
                type: 'success',
                message: l.t('updateSuccess', {
                  value: value
                })
              }));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_3 = _a.sent();
            dispatch(showNotification({
              type: 'error',
              message: l.t('updateFailed', {
                value: value
              })
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};