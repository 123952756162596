var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
var topicAdapter = createEntityAdapter({
  selectId: function selectId(topic) {
    return topic.name;
  }
});

var initialState = __assign({
  newNotificationsAvailable: false
}, topicAdapter.getInitialState());

var notificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    setNewNotificationsAvailable: function setNewNotificationsAvailable(state, _a) {
      var payload = _a.payload;
      state.newNotificationsAvailable = payload;
    },
    setTopics: topicAdapter.setAll,
    setTopic: topicAdapter.upsertOne
  }
});
export var setNewNotificationsAvailable = (_a = notificationSlice.actions, _a.setNewNotificationsAvailable),
    setTopics = _a.setTopics,
    setTopic = _a.setTopic;
export var topicSelectors = topicAdapter.getSelectors();
export default notificationSlice;