import { __assign } from "tslib";
import { createSelector } from "@reduxjs/toolkit";
import { isCustomSpace, isRoomSpace } from "../../helpers";
import { spaceSelectors } from "./spaceSlice";
import isToday from "date-fns/isToday";
import { RoomSpaceFilterTypes, RoomSpaceDropdownFilters, RoomStates, RoomStatesOrder, CleaningChoice } from "./types";

var selectSelf = function selectSelf(state) {
  return state.space;
};

export var selectSpaceById = spaceSelectors.selectById,
    selectSpaceEntities = spaceSelectors.selectEntities,
    selectAllSpaces = spaceSelectors.selectAll,
    selectAllSpaceIds = spaceSelectors.selectIds,
    selectTotalSpaces = spaceSelectors.selectTotal;
export var selectAllRoomSpaces = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var roomSpaces = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (isRoomSpace(space)) roomSpaces.push(space);
  });
  return roomSpaces;
});
export var selectAllRoomSpaceIds = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var roomSpaceIds = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (isRoomSpace(space)) roomSpaceIds.push(id);
  });
  return roomSpaceIds;
});
export var selectRoomSpaceEntities = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var roomSpaces = {};
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space)) return;
    roomSpaces[space.id] = space;
  });
  return roomSpaces;
});
export var selectAllCustomSpacesIds = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var CustomSpaceIds = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (isCustomSpace(space)) CustomSpaceIds.push(id);
  });
  return CustomSpaceIds;
});
export var selectAllCustomSpaces = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var customSpaces = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (isCustomSpace(space)) customSpaces.push(space);
  });
  return customSpaces;
});
export var selectAllRoomTypes = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var roomTypes = new Set();
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space)) return;
    roomTypes.add(space.room.code);
  });
  return Array.from(roomTypes);
});
export var selectCustomSpaceEntities = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var roomSpaces = {};
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isCustomSpace(space)) return;
    roomSpaces[space.id] = space;
  });
  return roomSpaces;
});
export var selectRoomSpacesBySearchInput = createSelector(selectSelf, function (_, searchInput) {
  return searchInput;
}, function (_a, rawSearchInput) {
  var entities = _a.entities,
      ids = _a.ids;
  var searchInput = rawSearchInput.toLowerCase();
  var roomSpaces = [];

  var containsSearchInput = function containsSearchInput(space) {
    return space.label.toLowerCase().includes(searchInput);
  };

  ids.forEach(function (id) {
    var space = entities[id];

    if (isRoomSpace(space) && containsSearchInput(space)) {
      roomSpaces.push(space);
    }
  });
  return roomSpaces;
});
export var selectActiveTaskIds = createSelector(selectSelf, function (_a) {
  var ids = _a.ids,
      entities = _a.entities;
  var taskIds = new Set();
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space) || space.room.tasks === undefined) return;
    space.room.tasks.forEach(function (task) {
      taskIds.add(task.id);
    });
  });
  return Array.from(taskIds);
});

var filterRoomSpacesWithFilters = function filterRoomSpacesWithFilters(filters, members, space) {
  var _a; // Not a room
  // The possible given filters


  var areaId = filters[RoomSpaceFilterTypes.AREA_ID];
  var roomCode = filters[RoomSpaceFilterTypes.ROOM_CODE];
  var searchText = (_a = filters[RoomSpaceFilterTypes.SEARCH_TEXT]) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase();
  if (!hasRoomCode(roomCode, space)) return false;
  if (areaId !== undefined && space.areaId !== areaId) return false; // Finally filter on search text

  if (searchText !== undefined) {
    if (space.label.includes(searchText) || space.room.users.some(function (a) {
      var _a, _b;

      return (_b = (_a = members[a.id]) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.name.toLocaleLowerCase().includes(searchText);
    })) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

var hasRoomCode = function hasRoomCode(roomCodes, space) {
  if (!(roomCodes === null || roomCodes === void 0 ? void 0 : roomCodes.length)) return true;
  return roomCodes === null || roomCodes === void 0 ? void 0 : roomCodes.some(function (roomCode) {
    var _a;

    return ((_a = space.room) === null || _a === void 0 ? void 0 : _a.code) === roomCode;
  });
};

var hasDropdownFilters = function hasDropdownFilters(filters) {
  var hasFilters = false;
  RoomSpaceDropdownFilters.forEach(function (filter) {
    if (filters[filter] !== undefined) hasFilters = true;
  });
  return hasFilters;
};

var hasExtras = function hasExtras(space) {
  var _a, _b;

  var guest = space.room.guest;
  var currentExtras = (_a = guest.current) === null || _a === void 0 ? void 0 : _a.services.length;
  var arrivingExtras = (_b = guest.next) === null || _b === void 0 ? void 0 : _b.services.length;
  return currentExtras || arrivingExtras;
};

var filterRoomSpacesWithDropdownFilters = function filterRoomSpacesWithDropdownFilters(filters, space) {
  var matchesFilter = false;
  if (!hasDropdownFilters(filters)) return true; // Return when there are no filters selected

  Object.keys(filters).forEach(function (filter) {
    switch (filter) {
      case RoomSpaceFilterTypes.GREEN_CHOICE:
        matchesFilter || (matchesFilter = space.room.cleaningChoice === CleaningChoice.GREEN);
        break;

      case RoomSpaceFilterTypes.ORANGE_CHOICE:
        matchesFilter || (matchesFilter = space.room.cleaningChoice === CleaningChoice.ORANGE);
        break;

      case RoomSpaceFilterTypes.ARRIVALS:
        matchesFilter || (matchesFilter = space.room.arriving);
        break;

      case RoomSpaceFilterTypes.DEPARTURES:
        matchesFilter || (matchesFilter = space.room.departing);
        break;

      case RoomSpaceFilterTypes.STAYOVERS:
        matchesFilter || (matchesFilter = space.room.staying);
        break;

      case RoomSpaceFilterTypes.OCCUPIED_TODAY:
        matchesFilter || (matchesFilter = !space.room.vacant && !!space.room.guest.current && isToday(new Date(space.room.guest.current.arrival)));
        break;

      case RoomSpaceFilterTypes.ROOM_CLEAN:
        matchesFilter || (matchesFilter = space.room.clean);
        break;

      case RoomSpaceFilterTypes.ROOM_DIRTY:
        matchesFilter || (matchesFilter = !space.room.clean);
        break;

      case RoomSpaceFilterTypes.WITH_EXTRAS:
        matchesFilter || (matchesFilter = !!hasExtras(space));
        break;

      case RoomSpaceFilterTypes.WITH_TASKS:
        matchesFilter || (matchesFilter = !!space.room.tasks.length);
        break;
    }
  });
  return matchesFilter;
};

export var selectRoomSpaceIdsByFilters = createSelector(function (state) {
  return state.ui.roomFilters;
}, function (state) {
  return state.space.ids;
}, function (state) {
  return state.user.entities;
}, function (state) {
  return state.space.entities;
}, function (roomFilters, ids, members, spaces) {
  var roomSpaceIds = [];
  ids.forEach(function (id) {
    var space = spaces[id];
    if (!isRoomSpace(space)) return;
    if (!filterRoomSpacesWithFilters(roomFilters, members, space)) return;
    if (!filterRoomSpacesWithDropdownFilters(roomFilters, space)) return;
    roomSpaceIds.push(id);
  });
  return roomSpaceIds;
});
export var selectCustomSpaceIdsBySorting = createSelector(selectSelf, selectAllCustomSpacesIds, function (state) {
  return state.ui.customSpaceSorting;
}, function (_a, ids, sorting) {
  var entities = _a.entities;

  if (sorting === void 0) {
    sorting = {
      key: "name",
      asc: true
    };
  }

  if (!sorting.key) return {
    sorting: sorting,
    ids: ids
  };

  var getSortMethod = function getSortMethod() {
    switch (sorting.key) {
      case "name":
        return function (a, b) {
          return a.label.trim().toLowerCase() < b.label.trim().toLowerCase() ? -1 : 1;
        };

      case "floor":
        return function (a, b) {
          return Number(a.floor) > Number(b.floor) ? -1 : 1;
        };

      default:
        return function (a, b) {
          return 0;
        };
    }
  };

  var sortMethod = getSortMethod();
  ids.sort(function (a, b) {
    var aSpace = entities[a];
    var bSpace = entities[b];

    var _a = sorting.asc ? [aSpace, bSpace] : [bSpace, aSpace],
        x = _a[0],
        y = _a[1];

    return sortMethod(x, y);
  });
  return {
    sorting: sorting,
    ids: ids
  };
});
export var selectRoomSpaceIdsBySorting = createSelector(selectSelf, selectRoomSpaceIdsByFilters, function (state) {
  return state.ui.roomSorting;
}, function (_a, ids, sorting) {
  var entities = _a.entities;

  if (sorting === void 0) {
    sorting = {
      key: "room",
      asc: true
    };
  }

  if (!sorting.key) return {
    sorting: sorting,
    ids: ids
  };

  var getSortMethod = function getSortMethod() {
    switch (sorting.key) {
      case "action":
        return function (x, y) {
          return x.room.cleaningChoice === CleaningChoice.GREEN || x.room.cleaningChoice === CleaningChoice.ORANGE ? -1 : 1;
        };

      case "room":
        return function (x, y) {
          return Number(x.label) > Number(y.label) ? 1 : -1;
        };

      case "roomStatus":
        return function (x, y) {
          return x.room.guest.current && x.room.guest.current.services.length > 0 ? -1 : 1;
        };

      case "arrivals":
        return function (x, y) {
          return x.room.guest.next && x.room.guest.next.services.length > 0 ? -1 : 1;
        };

      case "cleaningStatus":
        return function (x, y) {
          return RoomStatesOrder.indexOf(x.room.state) > RoomStatesOrder.indexOf(y.room.state) ? 1 : -1;
        };

      case "tasks":
        return function (x, y) {
          return x.room.tasks && x.room.tasks.length > y.room.tasks.length ? -1 : 1;
        };

      default:
        return function (x, y) {
          return 0;
        };
    }
  };

  var sortMethod = getSortMethod();
  ids.sort(function (a, b) {
    var roomSpaceA = entities[a];
    var roomSpaceB = entities[b];

    var _a = sorting.asc ? [roomSpaceA, roomSpaceB] : [roomSpaceB, roomSpaceA],
        x = _a[0],
        y = _a[1];

    return sortMethod(x, y);
  });
  return {
    sorting: sorting,
    ids: ids
  };
});
export var selectVacantBySpaceId = createSelector(selectSelf, function (_, ids) {
  return ids;
}, function (_a, ids) {
  var entities = _a.entities;
  var roomStatus = {
    leavers: 0,
    stayers: 0
  };
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space)) return;
    if (space.room.departing) roomStatus.leavers += 1;
    if (space.room.staying) roomStatus.stayers += 1;
    return;
  });
  return roomStatus;
});
export var selectAssigneesBySpaceId = createSelector(selectSelf, function (_, id) {
  return id;
}, function (_a, id) {
  var entities = _a.entities;
  var assignees = {};
  var space = entities[id];
  if (!isRoomSpace(space)) return {};
  space.room.users.forEach(function (assignee) {
    assignees[assignee.id] = assignee;
  });
  return assignees;
});
export var selectAssigneeBySpaceId = createSelector(function (state) {
  return state.space.entities;
}, function (_, spaceId) {
  return spaceId;
}, function (spaces, spaceId) {
  var space = spaces[spaceId];
  if (!isRoomSpace(space)) return undefined;
  if (space.room.users.length == 0) return undefined;
  return space.room.users[0];
});
export var selectAssignees = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var assignees = {};
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space)) return;
    space.room.users.forEach(function (assignee) {
      assignees[assignee.id] = assignee;
    });
  });
  return assignees;
});
export var selectAssigneeCounts = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      ids = _a.ids;
  var assignees = {};
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space)) return;
    space.room.users.forEach(function (assignee) {
      if (assignees[assignee.id]) {
        assignees[assignee.id] += 1;
      } else {
        assignees[assignee.id] = 1;
      }
    });
  });
  return assignees;
});
export var selectCurrentSpaceId = createSelector(selectSelf, function (data) {
  return data.selectedId;
});
export var selectCurrentSpace = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      selectedId = _a.selectedId;
  if (selectedId) return entities[selectedId];
  return undefined;
});
export var selectSpacesByIds = createSelector(selectSelf, function (_, ids) {
  return ids;
}, function (_a, ids) {
  var entities = _a.entities;
  var spaces = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (space === undefined) return;
    spaces.push(space);
  });
  return spaces;
});
export var selectRoomSpacesByIds = createSelector(selectSelf, function (_, ids) {
  return ids;
}, function (_a, ids) {
  var entities = _a.entities;
  var spaces = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (space === undefined || !isRoomSpace(space)) return;
    spaces.push(space);
  });
  return spaces;
});
export var selectCustomSpacesByIds = createSelector(selectSelf, function (_, ids) {
  return ids;
}, function (_a, ids) {
  var entities = _a.entities;
  var spaces = [];
  ids.forEach(function (id) {
    var space = entities[id];
    if (space === undefined || !isCustomSpace(space)) return;
    spaces.push(space);
  });
  return spaces;
});

var calculateStats = function calculateStats(stats, space) {
  var newStats = __assign({}, stats);

  var _a = space.room,
      departing = _a.departing,
      staying = _a.staying,
      arriving = _a.arriving,
      state = _a.state;
  if ([RoomStates.OUT_OF_INVENTORY, RoomStates.OUT_OF_ORDER].includes(state)) return stats;
  if (staying) newStats.staying += 1;

  if (departing) {
    newStats.departure += 1;
  }

  if (arriving) newStats.arrival += 1;
  return newStats;
};

export var selectHKMemberCleaningStats = createSelector(selectSelf, function (_, id) {
  return id;
}, function (_a, hkId) {
  var ids = _a.ids,
      entities = _a.entities;
  var stats = {
    staying: 0,
    arrival: 0,
    departure: 0
  };
  ids.forEach(function (id) {
    var space = entities[id];
    if (!isRoomSpace(space) || !space.room.users.some(function (assignee) {
      return assignee.id === hkId;
    })) return;
    stats = calculateStats(stats, space);
  });
  return stats;
});
export var makeSelectRoomSpaceById = function makeSelectRoomSpaceById() {
  return createSelector(selectSelf, function (_, spaceId) {
    return spaceId;
  }, function (_a, spaceId) {
    var entities = _a.entities;
    var space = entities[spaceId];
    return space;
  });
};
export var makeSelectCustomSpaceById = function makeSelectCustomSpaceById() {
  return createSelector(selectSelf, function (_, spaceId) {
    return spaceId;
  }, function (_a, spaceId) {
    var entities = _a.entities;
    var space = entities[spaceId];
    return space;
  });
};
export var selectCustomSpaceById = createSelector(selectSelf, function (_, id) {
  return id;
}, function (_a, id) {
  var entities = _a.entities;
  var space = entities[id];
  if (!isCustomSpace(space)) return undefined;
  return space;
});