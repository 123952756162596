var _a;

import { createSelector } from "@reduxjs/toolkit";
import { taskAdapter } from "./taskSlice";

var selectSelf = function selectSelf(state) {
  return state.task;
};

export var selectCurrentTask = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      selectedId = _a.selectedId;
  if (selectedId) return entities[selectedId];
  return undefined;
});
export var selectAllTasks = (_a = taskAdapter.getSelectors(), _a.selectAll),
    selectTaskById = _a.selectById,
    selectTaskEntities = _a.selectEntities,
    selectAllTaskIds = _a.selectIds;