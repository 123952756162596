var _a;

import { createSelector } from "@reduxjs/toolkit";
import { areaAdapter } from "./areaSlice";

var selectSelf = function selectSelf(state) {
  return state.area;
};

export var selectCurrentArea = createSelector(selectSelf, function (_a) {
  var entities = _a.entities,
      selectedId = _a.selectedId;
  if (selectedId) return entities[selectedId];
  return undefined;
});
export var selectAreaEntities = (_a = areaAdapter.getSelectors(), _a.selectEntities),
    selectAreaById = _a.selectById,
    selectAllAreas = _a.selectAll;
export default {
  selectAreaEntities: selectAreaEntities,
  selectAreaById: selectAreaById,
  selectAllAreas: selectAllAreas
};