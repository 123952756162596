var _a;

import { __assign } from "tslib";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export var reportExtraAdapter = createEntityAdapter();

var initialState = __assign(__assign({}, reportExtraAdapter.getInitialState()), {
  selectedId: undefined
});

var reportExtraSlice = createSlice({
  name: "reportExtra",
  initialState: initialState,
  reducers: {
    setReportExtras: reportExtraAdapter.setAll,
    setSelectedReportExtra: function setSelectedReportExtra(state, _a) {
      var payload = _a.payload;
      state.selectedId = payload;
    }
  }
});
export var setReportExtras = (_a = reportExtraSlice.actions, _a.setReportExtras),
    setSelectedReportExtra = _a.setSelectedReportExtra;
export default reportExtraSlice;