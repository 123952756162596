var _a;

import { createSelector } from "@reduxjs/toolkit";
import { propertyAdapter } from "./propertySlice";

var selectState = function selectState(state) {
  return state;
};

export var selectCurrentProperty = createSelector(selectState, function (_a) {
  var selectedPropertyId = _a.user.selectedPropertyId,
      property = _a.property;

  if (selectedPropertyId) {
    return property.entities[selectedPropertyId];
  }

  return undefined;
});
export var selectPropertyEntities = (_a = propertyAdapter.getSelectors(), _a.selectEntities),
    selectPropertyById = _a.selectById,
    selectAllProperties = _a.selectAll,
    selectAllPropertyIds = _a.selectIds;
export default {
  selectPropertyEntities: selectPropertyEntities,
  selectPropertyById: selectPropertyById,
  selectAllProperties: selectAllProperties
};