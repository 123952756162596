import { __assign, __awaiter, __generator } from "tslib";
import { addIssue, commitAttachments, commitIssueTransaction, deleteAttachment, removeIssue, setIssue, setIssues, setPaginationMeta } from "./issueSlice";
import l from "../../locale";
import { v4 as uuidv4 } from "uuid";
import api from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import { batch } from "react-redux";
import { createAttachments } from "../../services/helpers";
import { AttachmentsTransactionTypes, IssueTransactionTypes } from "../../types/transaction";
import { transactionErrorMessage, transactionSuccessMessage } from "../../helpers";
import { startLoading } from "../ui/uiSlice";
import { fetchAllIssues } from "./issueHelpers";
import { createKeyValueObject } from "../helpers";
export var fetchIssues = function fetchIssues() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var issuesArr, issues, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , fetchAllIssues()];

          case 1:
            issuesArr = _a.sent();
            issues = createKeyValueObject(issuesArr);
            dispatch(setIssues(issues));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("refreshFailed"),
              noOverride: true
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var getIssuesByPage = function getIssuesByPage(page, state, filter) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, issues, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Issues.getPage({
              page: page,
              state: state,
              filter: filter
            })];

          case 1:
            response = _a.sent();
            issues = createKeyValueObject(response.data);
            dispatch(setIssues(issues));
            dispatch(setPaginationMeta(response.meta));
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("refreshFailed"),
              noOverride: true
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var fetchIssue = function fetchIssue(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , api.Issues.getById(id)];

          case 1:
            response = _a.sent();
            dispatch(setIssue(response));
            return [3
            /*break*/
            , 3];

          case 2:
            error_3 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: l.t("failed")
            }));
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var addOrDeleteAttachments = function addOrDeleteAttachments(issue, attachments) {
  if (attachments === void 0) {
    attachments = [];
  }

  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var existingAttachments, newAttachments, newUploadedAttachments, removedAttachments_1, error_4;

      var _a;

      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _b.trys.push([0, 3,, 4]);

            dispatch(startLoading(AttachmentsTransactionTypes.ADD_ATTACHMENT));
            existingAttachments = (_a = issue === null || issue === void 0 ? void 0 : issue.attachments) !== null && _a !== void 0 ? _a : [];
            newAttachments = (existingAttachments === null || existingAttachments === void 0 ? void 0 : existingAttachments.length) == 0 ? attachments : attachments.filter(function (attach) {
              return attach.id === undefined && attach.url;
            });
            if (!(newAttachments.length > 0)) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , createAttachments(newAttachments)];

          case 1:
            newUploadedAttachments = _b.sent();
            dispatch(addIssueAttachments({
              issueId: issue.id,
              attachments: newUploadedAttachments
            }));
            _b.label = 2;

          case 2:
            removedAttachments_1 = [];
            existingAttachments.forEach(function (attach) {
              if (!attachments.includes(attach)) removedAttachments_1.push(attach);
            });
            batch(function () {
              removedAttachments_1.forEach(function (attachment) {
                return __awaiter(void 0, void 0, void 0, function () {
                  return __generator(this, function (_a) {
                    switch (_a.label) {
                      case 0:
                        return [4
                        /*yield*/
                        , api.Attachment["delete"](attachment.id)];

                      case 1:
                        _a.sent();

                        dispatch(deleteAttachment({
                          attachment: attachment,
                          issueId: issue.id
                        }));
                        return [2
                        /*return*/
                        ];
                    }
                  });
                });
              });
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _b.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: transactionErrorMessage(AttachmentsTransactionTypes.ADD_ATTACHMENT)
              }, AttachmentsTransactionTypes.ADD_ATTACHMENT));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateIssue = function updateIssue(oldIssue, updatedParams, updatedAttachments) {
  if (updatedAttachments === void 0) {
    updatedAttachments = [];
  }

  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("task");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 4,, 5]);

            dispatch(startLoading("update:issue"));
            if (!updatedParams) return [3
            /*break*/
            , 3];
            return [4
            /*yield*/
            , api.Issues.update(oldIssue.id, updatedParams)];

          case 2:
            _a.sent();

            dispatch(setIssue(__assign(__assign({}, oldIssue), updatedParams)));
            _a.label = 3;

          case 3:
            batch(function () {
              dispatch(addOrDeleteAttachments(oldIssue, updatedAttachments));
              dispatch(showNotification({
                type: "success",
                message: l.t("updateSuccess", {
                  value: value
                })
              }, "update:issue"));
            });
            return [3
            /*break*/
            , 5];

          case 4:
            error_5 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("updateFailed", {
                  value: value
                })
              }, "update:issue"));
            });
            return [3
            /*break*/
            , 5];

          case 5:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createIssue = function createIssue(issue, attachments) {
  if (attachments === void 0) {
    attachments = [];
  }

  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, issueId, newIssue, error_6;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("task");
            issueId = uuidv4();
            newIssue = __assign(__assign({}, issue), {
              id: issueId
            });
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading(IssueTransactionTypes.CREATE));
            return [4
            /*yield*/
            , api.Transactions.create([{
              issueId: newIssue.id,
              name: IssueTransactionTypes.CREATE,
              spaceId: issue.spaceId,
              createdAt: new Date().toISOString(),
              meta: {
                priority: newIssue.priority,
                label: newIssue.label,
                reportedBy: issue.reportedBy,
                description: newIssue.description
              }
            }])];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(addIssue(newIssue));
              (attachments === null || attachments === void 0 ? void 0 : attachments.length) > 0 && dispatch(addOrDeleteAttachments(newIssue, attachments));
              dispatch(showNotification({
                type: "success",
                message: l.t("addSuccess", {
                  value: value
                })
              }, IssueTransactionTypes.CREATE));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_6 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("addFailed", {
                  value: value
                })
              }, IssueTransactionTypes.CREATE));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var handleIssueTransaction = function handleIssueTransaction(actions) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var transactionName, error_7;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            transactionName = actions[0].transaction.name;
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            IssueTransactionTypes.CREATE;
            dispatch(startLoading(transactionName));
            return [4
            /*yield*/
            , api.Transactions.create(actions.map(function (action) {
              return action.transaction;
            }))];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(commitIssueTransaction(actions));
              dispatch(showNotification({
                type: "success",
                message: transactionSuccessMessage(transactionName)
              }, transactionName));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_7 = _a.sent();
            dispatch(showNotification({
              type: "error",
              message: transactionErrorMessage(transactionName)
            }, transactionName));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var addIssueAttachments = function addIssueAttachments(_a) {
  var issueId = _a.issueId,
      attachments = _a.attachments;
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var transaction, error_8;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            transaction = {
              issueId: issueId,
              name: AttachmentsTransactionTypes.ADD_ATTACHMENT,
              meta: {
                attachmentIds: attachments.map(function (a) {
                  return a.id;
                })
              }
            };
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading(transaction.name));
            return [4
            /*yield*/
            , api.Transactions.create([transaction])];

          case 2:
            _a.sent();

            dispatch(commitAttachments({
              issueId: issueId,
              attachments: attachments
            }));
            return [3
            /*break*/
            , 4];

          case 3:
            error_8 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: transactionErrorMessage(transaction.name)
              }, transaction.name));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteIssue = function deleteIssue(issueId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_9;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("task");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            dispatch(startLoading("delete:issue"));
            return [4
            /*yield*/
            , api.Issues["delete"](issueId)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(removeIssue(issueId));
              dispatch(showNotification({
                type: "success",
                message: l.t("deleteSuccess", {
                  value: value
                })
              }, "delete:issue"));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_9 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("deleteFailed", {
                  value: value
                })
              }, "delete:issue"));
            });
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};