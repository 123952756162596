import { __awaiter, __generator } from "tslib";
import AsyncStorage from "@react-native-async-storage/async-storage";
import jwtDecode from "jwt-decode";
import api from "./api"; // Helpers for the app/web

export var Auth = {
  get: function get() {
    return __awaiter(this, void 0, void 0, function () {
      var storedTokens, tokens, decodedToken, pry, role, sub;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , AsyncStorage.getItem("tokens")];

          case 1:
            storedTokens = _a.sent();
            if (!storedTokens) return [2
            /*return*/
            , null];
            tokens = JSON.parse(storedTokens);
            decodedToken = jwtDecode(tokens.token);
            pry = decodedToken.pry, role = decodedToken.role, sub = decodedToken.sub;
            return [2
            /*return*/
            , {
              token: tokens.token,
              refreshToken: tokens.refresh_token,
              property: pry,
              role: role,
              currentUserId: sub
            }];
        }
      });
    });
  },
  update: function update(token, refreshToken) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2
        /*return*/
        , AsyncStorage.setItem("tokens", JSON.stringify({
          token: token,
          refresh_token: refreshToken
        }))];
      });
    });
  },
  reset: function reset() {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2
        /*return*/
        , AsyncStorage.removeItem("tokens")];
      });
    });
  }
};

var getBlob = function getBlob(fileUri) {
  return __awaiter(void 0, void 0, void 0, function () {
    var resp, imageBody;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , fetch(fileUri)];

        case 1:
          resp = _a.sent();
          return [4
          /*yield*/
          , resp.blob()];

        case 2:
          imageBody = _a.sent();
          return [2
          /*return*/
          , imageBody];
      }
    });
  });
};

var uploadToS3 = function uploadToS3(presignedUrl, url) {
  return __awaiter(void 0, void 0, void 0, function () {
    var imageBody;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , getBlob(url)];

        case 1:
          imageBody = _a.sent();
          return [4
          /*yield*/
          , fetch(presignedUrl, {
            method: "PUT",
            body: imageBody,
            headers: {
              "Content-Type": "image/jpeg"
            }
          })];

        case 2:
          _a.sent();

          return [2
          /*return*/
          ];
      }
    });
  });
};

export var createAttachments = function createAttachments(attachments) {
  return __awaiter(void 0, void 0, void 0, function () {
    var newAttachments;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          newAttachments = [];
          return [4
          /*yield*/
          , Promise.all(attachments.map(function (attachment) {
            return __awaiter(void 0, void 0, void 0, function () {
              var res, id, url;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    if (attachment.url === undefined) return [2
                    /*return*/
                    ];
                    return [4
                    /*yield*/
                    , api.Attachment.add()];

                  case 1:
                    res = _a.sent();
                    id = res.id;
                    url = res.presignedUrl;
                    newAttachments.push({
                      id: id,
                      url: attachment.url
                    });
                    return [4
                    /*yield*/
                    , uploadToS3(url, attachment.url)];

                  case 2:
                    _a.sent();

                    return [2
                    /*return*/
                    ];
                }
              });
            });
          }))];

        case 1:
          _a.sent();

          return [2
          /*return*/
          , newAttachments];
      }
    });
  });
};
export default {
  Auth: Auth,
  createAttachments: createAttachments
};