import { __awaiter, __generator } from "tslib";
import api from "../../services/api";
export var fetchAllIssues = function fetchAllIssues() {
  return __awaiter(void 0, void 0, void 0, function () {
    var response, issuesArr;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , api.Issues.getPage({
            page: 1
          })];

        case 1:
          response = _a.sent();
          issuesArr = response.data;
          _a.label = 2;

        case 2:
          if (!(response.meta.next !== null)) return [3
          /*break*/
          , 4];
          return [4
          /*yield*/
          , api.Issues.getPage({
            page: response.meta.next
          })];

        case 3:
          response = _a.sent();
          issuesArr.push.apply(issuesArr, response.data);
          return [3
          /*break*/
          , 2];

        case 4:
          return [2
          /*return*/
          , issuesArr];
      }
    });
  });
};