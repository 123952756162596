export var CleaningChoice;

(function (CleaningChoice) {
  CleaningChoice["DEFAULT"] = "clean";
  CleaningChoice["GREEN"] = "skip_cleaning";
  CleaningChoice["ORANGE"] = "fresh_towels";
})(CleaningChoice || (CleaningChoice = {}));

export var SpaceType;

(function (SpaceType) {
  SpaceType["ROOM"] = "room";
  SpaceType["CUSTOM"] = "custom";
})(SpaceType || (SpaceType = {}));

export var RoomStates;

(function (RoomStates) {
  RoomStates["CLEAN_INSPECTED"] = "clean_inspected";
  RoomStates["CLEAN"] = "clean";
  RoomStates["DIRTY"] = "dirty";
  RoomStates["DIRTY_INSPECTED"] = "dirty_inspected";
  RoomStates["OUT_OF_ORDER"] = "out_of_order";
  RoomStates["OUT_OF_INVENTORY"] = "out_of_inventory";
})(RoomStates || (RoomStates = {}));

export var RoomStatesOrder = [RoomStates.CLEAN_INSPECTED, RoomStates.CLEAN, RoomStates.DIRTY, RoomStates.DIRTY_INSPECTED, RoomStates.OUT_OF_ORDER, RoomStates.OUT_OF_INVENTORY];
export var RoomSpaceFilterTypes;

(function (RoomSpaceFilterTypes) {
  RoomSpaceFilterTypes["ROOM_CODE"] = "room_code";
  RoomSpaceFilterTypes["AREA_ID"] = "area_id";
  RoomSpaceFilterTypes["GREEN_CHOICE"] = "green_choice";
  RoomSpaceFilterTypes["ORANGE_CHOICE"] = "orange_choice";
  RoomSpaceFilterTypes["ARRIVALS"] = "arrivals";
  RoomSpaceFilterTypes["STAYOVERS"] = "stayovers";
  RoomSpaceFilterTypes["DEPARTURES"] = "departures";
  RoomSpaceFilterTypes["WITH_EXTRAS"] = "with_extras";
  RoomSpaceFilterTypes["WITH_TASKS"] = "with_tasks";
  RoomSpaceFilterTypes["SEARCH_TEXT"] = "search_text";
  RoomSpaceFilterTypes["OCCUPIED_TODAY"] = "occupied_today";
  RoomSpaceFilterTypes["ROOM_DIRTY"] = "room_dirty";
  RoomSpaceFilterTypes["ROOM_CLEAN"] = "room_clean";
  RoomSpaceFilterTypes["RESET"] = "reset";
})(RoomSpaceFilterTypes || (RoomSpaceFilterTypes = {}));

export var RoomSpaceDropdownFilters = [RoomSpaceFilterTypes.GREEN_CHOICE, RoomSpaceFilterTypes.ORANGE_CHOICE, RoomSpaceFilterTypes.ARRIVALS, RoomSpaceFilterTypes.DEPARTURES, RoomSpaceFilterTypes.STAYOVERS, RoomSpaceFilterTypes.WITH_EXTRAS, RoomSpaceFilterTypes.OCCUPIED_TODAY, RoomSpaceFilterTypes.ROOM_CLEAN, RoomSpaceFilterTypes.ROOM_DIRTY, RoomSpaceFilterTypes.WITH_TASKS];