import { __awaiter, __generator } from "tslib";
import services from "../../services/api";
import l from "../../locale";
import { batch } from "react-redux";
import { showNotification } from "../ui/uiThunk";
import { addArea, removeArea, setAreas, upsertArea } from "./areaSlice";
export var fetchAreas = function fetchAreas() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var response, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.Areas.getAll()];

          case 1:
            response = _a.sent();
            dispatch(setAreas(response.data));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createArea = function createArea(area) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_1, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("area");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.Areas.create(area)];

          case 2:
            response_1 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                message: l.t("addSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(addArea(response_1));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_2 = _a.sent();
            dispatch(showNotification({
              message: l.t("addFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteArea = function deleteArea(areaId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("area");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.Areas["delete"](areaId)];

          case 2:
            _a.sent();

            batch(function () {
              dispatch(showNotification({
                message: l.t("addSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(removeArea(areaId));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_3 = _a.sent();
            dispatch(showNotification({
              message: l.t("deleteFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var updateArea = function updateArea(updatedParams, areaId) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var value, response_2, error_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            value = l.t("area");
            _a.label = 1;

          case 1:
            _a.trys.push([1, 3,, 4]);

            return [4
            /*yield*/
            , services.Areas.update(areaId, updatedParams)];

          case 2:
            response_2 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                message: l.t("addSuccess", {
                  value: value
                }),
                type: "success"
              }));
              dispatch(upsertArea(response_2));
            });
            return [3
            /*break*/
            , 4];

          case 3:
            error_4 = _a.sent();
            dispatch(showNotification({
              message: l.t("updateFailed", {
                value: value
              }),
              type: "error"
            }));
            return [3
            /*break*/
            , 4];

          case 4:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};