import { __awaiter, __generator } from "tslib";
import { batch } from "react-redux";
import services from "../../services/api";
import { showNotification } from "../ui/uiThunk";
import { setReportExtras } from "./reportExtraSlice";
import l from "../../locale";
export var fetchReportExtras = function fetchReportExtras() {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var data, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.ReportExtras.getAll()];

          case 1:
            data = _a.sent().data;
            dispatch(setReportExtras(data));
            return [3
            /*break*/
            , 3];

          case 2:
            error_1 = _a.sent();
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var createReportExtra = function createReportExtra(params) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.ReportExtras.create(params)];

          case 1:
            _a.sent();

            dispatch(fetchReportExtras());
            return [3
            /*break*/
            , 3];

          case 2:
            error_2 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("addFailed", {
                  value: l.t("photos").toLowerCase()
                })
              }));
            });
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};
export var deleteReportExtra = function deleteReportExtra(id) {
  return function (dispatch) {
    return __awaiter(void 0, void 0, void 0, function () {
      var error_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);

            return [4
            /*yield*/
            , services.ReportExtras["delete"](id)];

          case 1:
            _a.sent();

            dispatch(fetchReportExtras());
            return [3
            /*break*/
            , 3];

          case 2:
            error_3 = _a.sent();
            batch(function () {
              dispatch(showNotification({
                type: "error",
                message: l.t("deleteFailed", {
                  value: l.t("photos").toLowerCase()
                })
              }));
            });
            return [3
            /*break*/
            , 3];

          case 3:
            return [2
            /*return*/
            ];
        }
      });
    });
  };
};