export default {
  "delete": "{{value}} verwijderd!",
  deleteSuccess: "{{value}} is succesvol verwijderd.",
  deleteSuccess_plural: "{{value}} zijn succesvol verwijderd.",
  deleteFailed: "{{value}} is niet verwijderd.",
  assign: "Toegewezen!",
  assignSuccess: "{{value}} is succesvol toegewezen.",
  assignFailed: "{{value}} is niet toegewezen.",
  unassign: "Niet meer toegewezen!",
  unassignSuccess: "{{value}} is niet meer toegewezen.",
  unassignSuccess_plural: "{{value}} zijn niet meer toegewezen.",
  unassignFailed: "{{value}} niet toewijzen is mislukt.",
  roomCleaned: "Kamer is schoongemaakt!",
  finishCleaningFailed: "Kan kamer niet op schoon zetten",
  startCleaningFailed: "Beginnen met schoonmaken niet gelukt",
  stopCleaningFailed: "Stoppen met schoonmaken is niet gelukt",
  finishInspectionFailed: "Kamer inspecteren faalt",
  issueStatusUpdateFailed: "Taak status updaten is mislukt",
  issueStatusUpdateSuccess: "Status van de taak is geüpdatet",
  add: "{{value}} toegevoegd!",
  addSuccess: "{{value}} is succesvol toegevoegd.",
  addSuccess_plural: "{{value}} is succesvol toegevoegd.",
  addFailed: "{{value}} niet toegevoegd.",
  update: "{{value}} geüpdatet!",
  updateSuccess: "{{value}} is succesvol geüpdatet.",
  updateMessage_plural: "{{value}} zijn succesvol geüpdatet.",
  updateFailed: "{{value}} niet geüpdatet.",
  refresh: "{{value}} gegevens vernieuwd!",
  refreshFailed: "Gegevens niet kunnen vernieuwen!",
  sessionExpired: "Sessie verlopen, log opnieuw in.",
  "switch": "Wissel {{value}}",
  switchSuccess: "{{value}} gewisseld!",
  switchFailed: "{{value}} niet gewisseld.",
  switchNotificationLanguageFailed: "Kon notificatie taal niet wisselen.",
  approveEOD: "Goedgekeurd!",
  approveEODSuccess: "De dagafsluiting is goedgekeurd.",
  approveEODMFailed: "Goedkeuren mislukt.",
  weekOverviewSend: "Weekoverzicht verstuurd!",
  weekOverviewSendSuccess: "Weekoverzicht is succesvol verstuurd.",
  weekOverviewSendFailed: "Weekoverzicht versturen is mislukt.",
  subscribeToTopicFailed: "Aanmelden voor melding is mislukt",
  unsubscribeFromTopicSuccess: "Afgemeld van de melding",
  unsubscribeFromTopicFailed: "Afmelden van melding is mislukt",
  noInternet: "Geen internet",
  subscribeToTopicSuccess: "Aangemeld voor de melding",
  noInternetMsg: "Geen internet verbinding, controleer of de Wi-Fi aan staat.",
  failed: "Mislukt!",
  property: "Property",
  role: "Rol",
  user: "Member",
  space: "Kamer",
  customSpace: "Custom ruimte",
  area: "Sectie",
  areas: "Secties",
  remark: "Opmerking",
  endOfDay: "Dagafsluiting",
  photo: "Foto",
  photos: "Foto's",
  rateType: "Tarieftype",
  task: "Taak",
  tasks: "Taken",
  language: "Taal",
  notification: "Melding",
  notifications: "Meldingen",
  createUserFailed: "Let op: het is niet mogelijk om hetzelfde e-mailadres voor meerdere gebruikers / hotels te gebruiken.",
  hkStatistics: {
    notAvailable: "Statistieken zijn (nog) niet beschikbaar",
    atStart: "Begin",
    currently: "Huidig",
    departures: "Vertrekkers",
    carriedOver: "Doorgezet",
    carriedOverFromYesterday: "Gisteren doorgezet",
    dirtyVacant: "Alle vrij/vuile kamers",
    stayovers: "Blijvers",
    arrivals: "Aankomsten",
    roomsCleaned: "Kamers schoongemaakt",
    suitesCleaned: "Suites schoongemaakt"
  },
  hcsNotification: {
    heading: {
      poor: "IDPMS niet bereikbaar",
      bad: "IDPMS langere tijd niet bereikbaar"
    },
    content: {
      poor: "De kamer statussen zijn mogelijk niet up-to-date door een connectie probleem met IDPMS. Vraag aan de receptie om een supportcase aan te maken bij Amadeus (hospitality.support@amadeus.com) met als onderwerp: \"Hybrid Cloud Service is down for Hotel {{propertyName}}\"",
      bad: "De kamer statussen zijn niet up-to-date door een connectie probleem met IDPMS. Vraag aan de receptie om een supportcase aan te maken bij Amadeus (hospitality.support@amadeus.com) met als onderwerp: \"Hybrid Cloud Service is down for Hotel {{propertyName}}\""
    }
  }
};